import { ChevronIcon } from 'assets/icons';
import { CloseIcon } from 'assets/icons/icons';
import { Link } from 'react-router-dom';
import { Space, Row, Avatar, Select, Badge, message } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import Button from 'components/UI/Button';
import CustomButton from 'components/UI/Button';
import CustomSearchInput from 'components/CustomComponents/CustomSearchInput';
import DropdownMenu from 'components/CustomComponents/DropdownMenu';
import globalStyles from 'constants/globalStyles';
import { replacedTextData } from 'constants/common';
import LegacyAPI, { DashboardAPI } from 'api';
import Loader from 'components/Loader';
import Modal from 'components/Modals/Modal/Modal';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import UniversalTable from 'components/UniversalTable';
import { getUserFromStorage } from 'utils/storage';
import classes from './styles/Teams.module.css';

const initialAssign = {
  visible: false,
  data: {
    teamMember: {
      value: '',
      errorMessage: 'Fill this field',
      error: false,
    },
    role: {
      value: '',
      errorMessage: 'Fill this field',
      error: false,
    },
  },
};

// TODO: This is pretty standard for all tables in the project, they should be unified
class Column {
  constructor(title, dataIndex, sortBy, setSortBy, sortingDirection, setSortingDirection, render) {
    function headerCellClickHandler() {
      if (dataIndex !== sortBy) {
        setSortBy(dataIndex);
        return;
      }

      setSortingDirection((prev) => (prev === 'desc' ? 'asc' : 'desc'));
    }

    this.title = (
      <div onClick={headerCellClickHandler} className={classes.tableHeaderCell}>
        {title}
        {dataIndex === sortBy && <ChevronIcon direction={sortingDirection === 'desc' ? 'down' : 'up'} />}
      </div>
    );
    this.render = render;
    this.dataIndex = dataIndex;
  }
}

function TeamWidget({ item }) {
  const [assignNew, setAssignNew] = useState(initialAssign);
  const [loaded, setLoaded] = useState(false);
  const [deleteMember, setDeleteMember] = useState({
    visible: false,
    data: {},
  });
  const [team, setTeam] = useState([]);
  const userData = getUserFromStorage();
  const isAdmin = userData.role.toLowerCase().includes('admin');

  useEffect(() => {
    getTeam();
    // eslint-disable-next-line
  }, [item]);

  const getTeam = () => {
    LegacyAPI()
      .get('/LAR/team', { params: { lar_id: item.id } })
      .then((res) => {
        setTeam(res.data.team);
        setLoaded(true);
      });
  };

  const deleteTeamMember = () => {
    const { user_id, lar_id } = deleteMember.data;

    LegacyAPI()
      .delete('/LAR/team', { params: { user_id, lar_id } })
      .then(() => {
        // setTeam(state =>
        //     state.map(i =>
        //         i.user_id === user_id
        //             ? {
        //                 ...i,
        //                 role_name: null,
        //             }
        //             : i
        //     )
        // )
        getTeam();
        closeDeleteModel();

        message.success('Team member deleted successfully');
      })
      .catch((e) => {
        const error = e?.response?.data?.error;

        if (error) {
          message.error(error);
        }
      });
  };

  const closeDeleteModel = () => setDeleteMember({ show: null, data: null });

  const changeStatus = (role_name, lar_id, user_id) => {
    LegacyAPI()
      .post('/LAR/team', { lar_id, user_id, role_name })
      .then(() => {
        getTeam();
        message.success('Team member role changed successfully');
      });
  };

  const changeAssignVisibility = (visible) => setAssignNew((state) => ({ ...state, visible }));

  const changeRejectQuotes = (name, value) => {
    setAssignNew((state) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value,
          error: false,
        },
      },
    }));
  };

  const assignSubmit = () => {
    const { teamMember, role } = assignNew.data;

    LegacyAPI()
      .post('/LAR/team', {
        lar_id: item.id,
        user_id: teamMember.value.value,
        role_name: role.value,
      })
      .then(() => {
        getTeam();
        setAssignNew(initialAssign);
      });
  };

  const availableToAssign = team?.filter((i) => !i.role_name);

  return (
    <>
      <div
        style={{
          marginLeft: 60,
          textAlign: 'left',
          marginBottom: 10,
          fontWeight: 'bold',
        }}
      >
        Team
      </div>
      <Modal
        centered
        okText="Confirm"
        onCancel={closeDeleteModel}
        onOk={deleteTeamMember}
        title="Remove team member"
        visible={deleteMember.visible}
      >
        Are you sure you want to remove a team member?
      </Modal>
      <Modal
        centered
        className="reject-quotes assign-new"
        closable={null}
        footer={
          <>
            <Button text="Cancel" onClick={() => changeAssignVisibility(false)} />
            <Button
              disabled={!availableToAssign.length}
              onClick={assignSubmit}
              primary
              style={{ marginLeft: 'auto' }}
              text="Assign team member"
            />
          </>
        }
        onCancel={() => changeAssignVisibility(false)}
        title="Assign team member"
        visible={assignNew.visible}
        width={512}
      >
        Please select a team member to assign
        <div className="reject-body">
          {availableToAssign.length ? (
            <>
              <DropdownMenu
                options={availableToAssign.map((i) => ({
                  preview: i.user_name,
                  value: i.user_id,
                }))}
                disabled={!availableToAssign.length}
                vectorIcon
                title="Team member"
                onSelect={({ value }) => {
                  const member = availableToAssign.find((i) => i.user_id === value);

                  changeRejectQuotes('teamMember', {
                    value,
                    preview: member.user_name,
                    member,
                  });
                  changeRejectQuotes('role', member.role);
                }}
                className="bordered"
                errorMessage={assignNew.data.teamMember.errorMessage}
                error={assignNew.data.teamMember.error}
                value={assignNew.data.teamMember.value.preview}
              />
              <DropdownMenu
                options={
                  assignNew.data.teamMember.value?.member?.available_roles.map((i) => ({
                    preview: replacedTextData[i] || i,
                    value: i,
                  })) || []
                }
                vectorIcon
                title="Role"
                onSelect={({ value }) => {
                  changeRejectQuotes('role', value);
                }}
                className="bordered"
                errorMessage={assignNew.data.role.errorMessage}
                error={assignNew.data.role.error}
                value={assignNew.data.role.value}
              />
            </>
          ) : (
            <p style={{ textAlign: 'center' }}>There are no additional team members</p>
          )}
        </div>
      </Modal>
      {loaded ? (
        <div>
          {team.map(
            (i, idx) =>
              i.role_name && (
                <Row style={{ marginBottom: 10 }} key={idx} align="middle" justify="space-between">
                  <Space size={64}>
                    <Space style={{ marginLeft: 60, width: 200 }} size={32}>
                      {userData.email === i.email ? (
                        <Badge
                          style={{
                            right: '1px',
                            top: 5,
                            width: 12,
                            minWidth: 12,
                            height: 12,
                            background: globalStyles.main_blue_color,
                          }}
                          dot
                        >
                          <Avatar size={34} src={i.photo} icon={<UserOutlined />} />
                        </Badge>
                      ) : (
                        <Avatar size={34} src={i.photo} icon={<UserOutlined />} />
                      )}
                      <Link to={`/onboarding/users/${i.user_id}`}>{i.user_name}</Link>
                    </Space>
                    {isAdmin ? (
                      <Select
                        onChange={(e) => changeStatus(e, item.id, i.user_id)}
                        style={{
                          minWidth: 150,
                        }}
                        value={i.available_roles[0]}
                      >
                        {i.available_roles.map((i) => (
                          <Select.Option value={i}>{replacedTextData[i] || i}</Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <span>{i.role_name}</span>
                    )}
                    <span>{i.email}</span>
                    <span>{i.last_online}</span>
                  </Space>
                  <CloseIcon
                    onClick={() => {
                      setDeleteMember(() => ({
                        visible: true,
                        data: {
                          user_id: i.user_id,
                          lar_id: item.id,
                        },
                      }));
                    }}
                    style={{ color: '#bfbfbf' }}
                  />
                </Row>
              ),
          )}
          <CustomButton
            onClick={() => changeAssignVisibility(item.id)}
            primary
            style={{ margin: '24px 0 0 60px', padding: '4px 8px' }}
            text="Add user to the team"
            type="button"
          />
        </div>
      ) : (
        <Loader
          color={{
            '--bgColor': 'var(--main-blue-color)',
            '--size': '40px',
            '--wrapper_size': '40px',
          }}
        />
      )}
    </>
  );
}

export default function Teams() {
  const [sortBy, setSortBy] = useState('id');
  const [sortingDirection, setSortingDirection] = useState('desc');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [paginationTotal, setPaginationTotal] = useState(1);
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const API = useMemo(() => new DashboardAPI(), []);

  const tableColumns = useMemo(
    () => [
      new Column('LAR ID', 'id', sortBy, setSortBy, sortingDirection, setSortingDirection, (id) => `#${id}`),
      new Column('Status', 'status_name', sortBy, setSortBy, sortingDirection, setSortingDirection, (text) => (
        <Space size="large">
          <div className="online_status">
            <span>{text}</span>
            {text === 'Loan Processing' && (
              <div className="options">
                <span
                  style={{
                    background: text === 'Active' ? '#FF4D4F' : '#D9D9D9',
                    color: text === 'Active' ? '#fff' : '#1F1F1F',
                  }}
                >
                  {text === 'Active' ? 2 : 0}
                </span>
                <span
                  style={{
                    background: text === 'Active' ? '#FFC53D' : '#D9D9D9',
                    color: text === 'Active' ? '#fff' : '#1F1F1F',
                  }}
                >
                  {text === 'Active' ? 2 : 0}
                </span>
                <span
                  style={{
                    background: text === 'Active' ? '#73D13D' : '#D9D9D9',
                    color: text === 'Active' ? '#fff' : '#1F1F1F',
                  }}
                >
                  {text === 'Active' ? 2 : 0}
                </span>
              </div>
            )}
          </div>
        </Space>
      )),
      new Column('Recent activity', 'last_update', sortBy, setSortBy, sortingDirection, setSortingDirection),
      new Column('Purpose - Style', 'loan_purpose_name', sortBy, setSortBy, sortingDirection, setSortingDirection),
      new Column('Street', 'property_address', sortBy, setSortBy, sortingDirection, setSortingDirection),
      new Column(
        'Broker Co/MLO',
        'broker_co_and_broker_blm',
        sortBy,
        setSortBy,
        sortingDirection,
        setSortingDirection,
        (_, i) => [i.broker_company, i.broker_full_name].join(' '),
      ),
      new Column(
        'Borrower/Guarantor',
        'borrower_and_guarantor',
        sortBy,
        setSortBy,
        sortingDirection,
        setSortingDirection,
      ),
      new Column(
        'Lender Co/LAE',
        'lender_co_and_lae',
        sortBy,
        setSortBy,
        sortingDirection,
        setSortingDirection,
        (_, i) => [i.lender_company, i.lender_full_name].join(' '),
      ),
      {
        title: '',
        dataIndex: 'lar_db_link',
        key: 'lar_db_link',
        render: (bool, el, idx) =>
          bool && (
            <Link
              to={{
                pathname: `/onboarding/lar_db/${el.id}`,
                state: {
                  pageBg: '#F5F5F5',
                  sortBy,
                  view: 'pipeline',
                  total: paginationTotal,
                  counter: idx + 1,
                },
              }}
              style={{
                fontWeight: 'bold',
                color: '#5744E4',
                whiteSpace: 'nowrap',
              }}
            >
              LAR DB
            </Link>
          ),
      },
    ],
    [paginationTotal, sortBy, sortingDirection],
  );

  const getLARs = useCallback(() => {
    API.getItemsList(sortBy, sortingDirection, pagination.current, pagination.pageSize, {
      larId: searchValue || undefined,
    }).then((data) => {
      setData(
        data.lars.map((i) => ({
          ...i,
          expandable: true,
          lar_db_link: i.status_name !== 'Incomplete',
          actions: {
            // note: true,
            notification: true,
            archive: true,
          },
        })),
      );

      setPaginationTotal(data.total);
    });
  }, [API, pagination, searchValue, sortBy, sortingDirection]);
  useEffect(getLARs, [getLARs]);

  return (
    <div className="scenarios-page">
      <div className="scenarios-page__inner">
        <div className="section_title_wrapper">
          <Row justify="space-between">
            <SectionTitle title="Loan Teams" />
            <CustomSearchInput
              hideDropdown
              onlyNumbers
              placeholder="Search by LAR ID"
              searchFunc={(val) => setSearchValue(val)}
            />
          </Row>
        </div>
        <div style={{ marginTop: 25 }} />
        <UniversalTable
          className="universal-table_default-padding universal-table_centered-content teams-table"
          columns={tableColumns}
          dataSource={data}
          expandable={{
            expandedRowRender: (item) => <TeamWidget item={item} />,
            rowExpandable: (record) => record.expandable,
          }}
          pagination={{
            ...pagination,
            total: paginationTotal,
          }}
          rowKey="id"
          scroll={{ x: 1100 }}
          setPagination={(p) => {
            setPaginationTotal(p.total);
            delete p.total;
            setPagination(p);
          }}
          withHeader={false}
        />
      </div>
    </div>
  );
}
