import 'pages/Onboarding/styles/main_onboarding.css';
import { Badge, Dropdown } from 'antd';
import { Route, Link, useHistory, useLocation } from 'react-router-dom';
import { setActiveRoute } from 'store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useCallback, memo, Fragment } from 'react';
import CustomButton from 'components/UI/Button';
import Logo from 'components/OnBoarding/Logo';
import socket from 'constants/socket';
import useResize from 'constants/useResize';
import NotificationBanners from 'components/NotificationBanners';
import PageHeader from 'components/OnBoarding/PageHeader';
import Navigations from './Navigations/index';
import API from '../api';

const MainNavigationOnBoarding = memo(() => {
  const history = useHistory();
  const { userData, activeRoute, fullLeftPanelSize, unreadChatMessages } = useSelector((store) => store.user);
  const { live_auctions, quote_results_count, scenarios_count } = useSelector((store) => store.dashboard.metrics);
  const location = useLocation();
  const dispatch = useDispatch();
  const { innerWidth } = useResize();

  useEffect(() => {
    Notification.requestPermission();

    const url = location.pathname.split('/')[2];
    if (url) {
      dispatch(setActiveRoute(url));
    } else {
      history.push('/404');
    }
  }, [location.pathname, history, dispatch]);

  useEffect(() => () => socket && socket.disconnect(), []);

  function OldSchoolMenuLink({ label, type, pathname, path, icon, total }) {
    if (!label) {
      return null;
    }

    function isActive() {
      return activeRoute === pathname || (activeRoute === 'admin-settings' && pathname === 'settings');
    }

    const handleCreate = (type) => () => {
      API()
        .get('/LAR/creation/data_for_form', {
          params: {
            borrower_id: userData?.id,
            creator: 'Borrower',
            type,
          },
        })
        .then((res) => {
          window.open(`/lar_app/create${res.data.token}`, '_blank');
        });
    };

    const items = [
      {
        key: '1',
        label: <div onClick={handleCreate('scenario')}>Scenario</div>,
      },
      {
        key: '2',
        label: <div onClick={handleCreate('auction')}>Auction</div>,
      },
    ];

    return type === 'button' && !fullLeftPanelSize ? (
      <div style={{ margin: 14, maxWidth: '100%' }}>
        <Badge count={total}>
          {/borrower/i.test(userData?.role) ? (
            <Dropdown trigger="click" menu={{ items }} placement="bottomLeft" arrow>
              <CustomButton className="btn_red btn_red_outlined" text={label} svg={icon} />
            </Dropdown>
          ) : (
            <CustomButton
              className="btn_red btn_red_outlined"
              key={pathname}
              style={{ maxWidth: '100%' }}
              onClick={() => {
                dispatch(setActiveRoute(pathname));
                history.push(path);
              }}
              text={label}
              svg={icon}
            />
          )}
        </Badge>
      </div>
    ) : (
      <Link
        key={pathname}
        className={`nav_bar_link ${isActive() && type !== 'button' ? 'nav_bar_active_link' : ''}`}
        onClick={() => dispatch(setActiveRoute(pathname))}
        to={path}
      >
        {icon}
        <Badge count={total} offset={[5, 2]}>
          <span className="action-item-label">{label}</span>
        </Badge>
      </Link>
    );
  }

  const renderSwitchRoute = useCallback(
    () => (
      <div
        style={{ backgroundColor: history.location.state?.pageBg ?? '#fff' }}
        className={`wrapper_onboarding__main_content ${
          fullLeftPanelSize ? 'wrapper_onboarding__main_content_full' : ''
        }`}
      >
        {Navigations.mainNavigationOnboarding.map((route, index) => {
          const userAccessLink =
            route.userAccess && (route.userAccess === 'all' ? true : route.userAccess.includes(userData?.role));

          if (!userAccessLink) {
            return null;
          }

          return <Route path={route.path} exact component={route.main} key={index} />;
        })}
      </div>
      // eslint-disable-next-line
    ),
    [fullLeftPanelSize, userData?.role, history.location.state?.pageBg],
  );

  return (
    <Route>
      {innerWidth > 900 || !['Borrower', ''].includes(userData?.role) ? (
        <>
          {innerWidth > 700 && <Logo />}
          <PageHeader />
          <NotificationBanners />
          {innerWidth > 700 && (
            <div
              className={
                fullLeftPanelSize
                  ? 'wrapper_onboarding__wrapper_links wrapper_onboarding__wrapper_links_hidden'
                  : 'wrapper_onboarding__wrapper_links'
              }
            >
              <div style={{ padding: '15px 0' }}>
                {Navigations.mainNavigationOnboarding
                  .filter((i) => !i.hidden)
                  .map((item, i) => {
                    if (item?.path === '/onboarding/messages') {
                      item.total = unreadChatMessages;
                    }
                    if (/lender/i.test(userData?.role)) {
                      if (item?.path === '/onboarding/finished-auctions') {
                        item.total = quote_results_count;
                      }
                      if (item?.path === '/onboarding/scenarios') {
                        item.total = scenarios_count;
                      }
                      if (item?.path === '/onboarding/live_auctions') {
                        item.total = live_auctions;
                      }
                    }
                    const userAccessLink = item.userAccess === 'all' ? true : item.userAccess.includes(userData?.role);

                    if (!userAccessLink) {
                      return null;
                    }

                    return (
                      <Fragment key={i}>
                        <OldSchoolMenuLink {...item} userAccessLink={userAccessLink} />
                        {item.hr && <hr />}
                      </Fragment>
                    );
                  })}
              </div>
            </div>
          )}
          <>{renderSwitchRoute()}</>
        </>
      ) : (
        <div className="wrapper_admin_panel_content_responsive">
          <div className="admin_panel_content_responsive">
            <span>Admin panel is not available on this resolution</span>
            <span>Please use the device with width at least 900px</span>
          </div>
        </div>
      )}
    </Route>
  );
});

export default MainNavigationOnBoarding;
