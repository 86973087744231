import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import { formatPhone } from 'constants/formatPhone';
import { Button, Col, Image, message, Row, Spin, Typography } from 'antd';
import { setUserData } from 'store/actions/userActions';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import API from 'api';
import BrokerLandingConsentLinks from 'components/OnBoarding/BrokerAdmin/BrokerLandingConsentLinks';
import BrokerLandingHeader from 'components/OnBoarding/BrokerAdmin/BrokerLandingHeader';
import BrokerLandingPopup from 'components/OnBoarding/BrokerAdmin/BrokerLandingPopup';
import brokerPlaceholder from 'assets/images/avatar_placeholder_large.png';
import copy from 'assets/images/copy-grey.svg';
import CustomButton from 'components/UI/Button';
import CustomPhoneInput from 'components/CustomComponents/PhoneInput';
import CustomTextInput from 'components/CustomComponents/TextInput';
import jwtDecode from 'jwt-decode';
import Keys from 'constants/helper';
import regExp from 'constants/regExp';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import TextArea from 'components/CustomComponents/TextArea';
import TextInput from 'components/CustomComponents/TextInput';
import useNestedCheckError from 'constants/useNestedCheckError';
import useResize from 'constants/useResize';
import formRules from 'constants/formRules';
import ResetPasswordPopup from './ResetPasswordPopup';

import classes from './styles/BrokerLanding.module.css';

const bulletPoints = [
  'View, track, and close your loan.',
  'Our complete digital process saves everyone time.',
  'Receive real quotes from multiple lenders within a few hours.',
];

class InputParams {
  constructor(title, value, fullLine, maxLength) {
    this.error = false;
    this.errorMessage = '';
    this.full_line = fullLine ?? false;
    this.maxLength = maxLength ?? 255;
    this.required = true;
    this.title = title ?? '';
    this.value = value ?? '';
  }
}

const defaultSignInState = {
  first_name: new InputParams(),
  last_name: new InputParams(),
  phone: new InputParams(),
  email: new InputParams(undefined, undefined, true),
  email_confirmation: new InputParams(undefined, undefined, true),
  password: new InputParams(undefined, undefined, true),
};

export default function BrokerLanding({ previewMode }) {
  const { companyDomain, brokerDomain } = useParams();
  const { innerWidth } = useResize();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [activeModal, setActiveModal] = useState({ show: '', data: null });
  const [activeSignIn, setActiveSignIn] = useState(defaultSignInState);
  const [landingData, setLandingData] = useState({});
  const [landingLinks, setLandingLinks] = useState(null);
  const [errors, setErrors] = useState('');
  const [login, setLogin] = useState(false);
  const { checkEmpty } = useNestedCheckError(activeSignIn, setActiveSignIn);

  useEffect(() => {
    if (!previewMode) {
      const urlParams = new URLSearchParams(location.search);
      const login = urlParams.get('login');
      const token = urlParams.get('token');

      if (token) {
        localStorage.setItem(Keys.JWT_TOKEN, token);
      }

      setLogin(['True', ''].includes(login));

      if (token) {
        const jwtData = jwtDecode(token);
        if (jwtData) {
          showSetUp(true);
          setActiveModal((prev) => ({
            ...prev,
            data: {
              ...prev.data,
              firstName: {
                ...prev.data.firstName,
                value: jwtData.name,
              },
              lastName: {
                ...prev.data.lastName,
                value: jwtData.surname,
              },
              phone: {
                ...prev.data.phone,
                value: jwtData.phone,
              },
              email: {
                ...prev.data.email,
                value: jwtData.email,
              },
            },
          }));

          setActiveSignIn((state) => {
            const result = {};

            Object.entries(state).forEach((i) => {
              const value = jwtData[i[0]] ?? i[1].value;
              result[i[0]] = { ...i[1], value };
            });

            return result;
          });
        }
      }
    }
  }, [previewMode, location.search]);

  useEffect(() => {
    if (!previewMode) {
      API()
        .get('/common/custom-landing-data', {
          params: { domain: companyDomain, broker_domain: brokerDomain },
        })
        .then(({ data }) => {
          const { broker_custom_landing_data, custom_landing_data, broker_info, company_info } = data;
          setLandingData((prev) => ({
            ...prev,
            address: `${broker_custom_landing_data.street}, ${broker_custom_landing_data.city} ${broker_custom_landing_data.state}`,
            broker_full_name: broker_custom_landing_data.broker_full_name,
            broker_phone: broker_info.phone,
            broker_work_phone: broker_info.work_phone,
            broker_id: broker_custom_landing_data.broker_id,
            broker_NMLS: broker_info.nmls,
            broker_picture: broker_custom_landing_data.broker_picture,
            brokerDomain: broker_custom_landing_data.broker_domain,
            color_code: custom_landing_data.color_code || '#293BBC',
            company_logo: custom_landing_data.company_logo,
            company_NMLS: company_info.nmls,
            company_short_name: custom_landing_data.company_short_name,
            direct_phone: broker_custom_landing_data.phone,
            domain: custom_landing_data.domain,
            email: broker_info.email,
            id: custom_landing_data.id,
            is_mobile: broker_custom_landing_data.is_mobile,
            loading: true,
          }));
        })
        .catch(() => history.push('/404'));
    } else {
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get('token');
      const data = jwtDecode(token);

      // TODO: is_mobile
      setLandingData((prev) => ({
        ...prev,
        address: `${data.street}, ${data.city} ${data.state}`,
        broker_full_name: data.broker_full_name,
        broker_id: data.broker_id,
        broker_NMLS: data.nmls,
        broker_picture: data.broker_picture,
        brokerDomain: data.broker_domain,
        color_code: data.color_code ?? '#293BBC',
        company_logo: data.company_logo,
        company_NMLS: data.company_NMLS,
        company_short_name: data.company_short_name,
        direct_phone: data.phone,
        broker_work_phone: data.work_phone,
        domain: data.domain,
        email: data.email,
        id: data.id,
        is_mobile: data.is_mobile,
        loading: true,
      }));
    }
  }, [previewMode, location, history, brokerDomain, companyDomain]);

  useEffect(() => {
    API()
      .get('/common/iharmoni-terms')
      .then(({ data }) => setLandingLinks(data.documents));
  }, []);

  useEffect(() => {
    if (activeModal.show === 'share') {
      setActiveModal((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          link: {
            ...prev.data.link,
            value: window.location.href,
          },
        },
      }));
    }
  }, [activeModal.show]);

  function handleApplyBroker(e) {
    e.preventDefault();

    const { email, password, email_confirmation, first_name, last_name, phone } = activeSignIn;

    const { error, obj } = checkEmpty(activeSignIn);
    let signError = false;

    setActiveSignIn(obj);

    if (error) {
      return;
    }

    function setFunc(name, errMessage) {
      setActiveSignIn((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          error: true,
          errorMessage: errMessage,
        },
      }));
    }

    if (!regExp.email.test(email.value)) {
      setFunc('email', 'Incorrect email format');
      signError = true;
    }

    if (password.value.length <= 5) {
      setFunc('password', 'Enter at least 6 characters');
      signError = true;
    }

    if (phone.value.length < 10) {
      setFunc('phone', 'Phone number must be 10 numbers');
      signError = true;
    }

    if (email.value !== email_confirmation.value) {
      setFunc('email', 'Emails should match');
      setFunc('email_confirmation', 'Emails should match');
      signError = true;
    }

    if (signError) {
      return;
    }

    const urlParams = new URLSearchParams(location.search);
    const brokerId = Number(urlParams.get('broker_id'));

    API()
      .post('/sign_in/borrower/register', {
        name: first_name.value,
        surname: last_name.value,
        phone: phone.value,
        email: email.value,
        password: password.value,
        domain: landingData.domain,
        broker_id: brokerId,
      })
      .then(({ data }) => {
        localStorage.setItem(Keys.JWT_TOKEN, data.access_token);

        dispatch(
          setUserData({
            role: data.role,
            avatar: data.profile_photo,
            token: data.access_token,
          }),
        );

        history.push('/lar_app/create');
      })
      .catch((err) => {
        setErrors(err?.response?.data?.error);
      });
  }

  function onChangeModal({ target: { name, value } }) {
    setActiveModal((prev) => {
      let additionalChanges = {};
      if (name === 'email' && prev.data.email_confirmation !== undefined) {
        additionalChanges = {
          email_confirmation: {
            ...prev.data.email_confirmation,
            error: false,
          },
        };
      } else if (name === 'email_confirmation') {
        additionalChanges = {
          email: {
            ...prev.data.email,
            error: false,
          },
        };
      }
      return {
        ...prev,
        data: {
          ...prev.data,
          [name]: {
            ...prev.data[name],
            value,
            error: false,
          },
          responseError: false,
          ...additionalChanges,
        },
      };
    });
  }

  function onApplyNowChange(el, e) {
    const { value } = e.target;

    setActiveSignIn((prev) => {
      let additionalChanges = {};

      if (el[0] === 'email' && prev.email_confirmation !== undefined) {
        additionalChanges = {
          email_confirmation: {
            ...prev.email_confirmation,
            error: false,
          },
        };
      } else if (el[0] === 'email_confirmation') {
        additionalChanges = {
          email: {
            ...prev.email,
            error: false,
          },
        };
      }

      return {
        ...prev,
        [el[0]]: {
          ...prev[el[0]],
          value,
          error: false,
        },
        ...additionalChanges,
      };
    });
  }

  function logIn(e) {
    e.preventDefault();
    let err = false;

    Object.entries(activeModal.data).forEach((i) => {
      if (i[0] === 'email' && !regExp.email.test(i[1].value)) {
        setActiveModal((state) => ({
          ...state,
          data: {
            ...state.data,
            [i[0]]: {
              ...state.data[i[0]],
              error: true,
              errorMessage: 'Incorrect email format',
            },
          },
        }));
        err = true;
      } else if (i[0] === 'password' && i[1].value.length <= 7) {
        setActiveModal((state) => ({
          ...state,
          data: {
            ...state.data,
            [i[0]]: {
              ...state.data[i[0]],
              error: true,
              errorMessage: 'Enter at least 8 characters',
            },
          },
        }));
        err = true;
      }
    });

    if (err) {
      return;
    }

    API()
      .post('/sign_in/borrower/login', {
        email: activeModal.data.email.value,
        password: activeModal.data.password.value,
        domain: landingData.domain,
      })
      .then(({ data }) => {
        localStorage.setItem(Keys.JWT_TOKEN, data.access_token);
        dispatch(
          setUserData({
            role: data.role,
            avatar: data.profile_photo,
            token: data.access_token,
          }),
        );

        setTimeout(() => history.push('/onboarding/dashboard'), 1e3);
      })
      .catch((err) => {
        console.error(err);
        setActiveModal((state) => ({
          ...state,
          data: {
            ...state.data,
            responseError: true,
            email: {
              ...state.data.email,
              error: true,
            },
            password: {
              ...state.data.password,
              error: true,
            },
          },
        }));
      });
  }

  function sendShare(e) {
    e?.preventDefault();
    let error = false;

    Object.entries(activeModal.data).forEach((i) => {
      if (!i[1].value && i[1] instanceof Object) {
        setActiveModal((state) => ({
          ...state,
          data: {
            ...state.data,
            [i[0]]: {
              ...state.data[i[0]],
              error: true,
              errorMessage: 'Fill this label',
            },
          },
        }));

        error = true;
      } else if (i[0] === 'email' && !regExp.email.test(i[1].value)) {
        setActiveModal((state) => ({
          ...state,
          data: {
            ...state.data,
            [i[0]]: {
              ...state.data[i[0]],
              error: true,
              errorMessage: 'Incorrect email format',
            },
          },
        }));

        error = true;
      }
    });

    if (error) {
      return;
    }

    API()
      .post('/LAR/landing/borrower/share-landing-page', {
        domain: landingData.domain,
        email: activeModal.data.email.value,
      })
      .then(() => {
        message.success('Email successfully sent');
        closeModal();
      })
      .catch((err) => {
        if (err?.response?.data?.error === 'cant find user') {
          setActiveModal((state) => ({
            ...state,
            data: {
              ...state.data,
              email: {
                ...state.data.email,
                error: true,
                errorMessage: err.response.data.error,
              },
            },
          }));
        }
      });
  }

  function setUp(e) {
    e.preventDefault();
    let setupErr = false;

    Object.entries(activeModal.data).forEach((i) => {
      if (!i[1].value && i[1] instanceof Object) {
        setActiveModal((state) => ({
          ...state,
          data: {
            ...state.data,
            [i[0]]: {
              ...state.data[i[0]],
              error: true,
              errorMessage: 'Fill this label',
            },
          },
        }));
        setupErr = true;
      } else if (i[0] === 'email' && !regExp.email.test(i[1].value)) {
        setActiveModal((state) => ({
          ...state,
          data: {
            ...state.data,
            [i[0]]: {
              ...state.data[i[0]],
              error: true,
              errorMessage: 'Incorrect email format',
            },
          },
        }));
        setupErr = true;
      } else if (i[0] === 'password' && i[1].value.length < 7) {
        setActiveModal((state) => ({
          ...state,
          data: {
            ...state.data,
            [i[0]]: {
              ...state.data[i[0]],
              error: true,
              errorMessage: 'Password is too short',
            },
          },
        }));
        setupErr = true;
      }
      if (i[0] === 'email_confirmation' && i[1].value !== activeModal.data.email.value) {
        setActiveModal((state) => ({
          ...state,
          data: {
            ...state.data,
            email: {
              ...state.data.email,
              error: true,
              errorMessage: 'Emails should match',
            },
            email_confirmation: {
              ...state.data.email_confirmation,
              error: true,
              errorMessage: 'Emails should match',
            },
          },
        }));
        setupErr = true;
      }
    });

    if (setupErr) {
      return;
    }

    const urlParams = new URLSearchParams(history.location.search);
    const broker_id = parseInt(urlParams.get('broker_id'));

    API()
      .post('/sign_in/borrower/register', {
        name: activeModal.data.firstName.value,
        surname: activeModal.data.lastName.value,
        phone: activeModal.data.phone.value,
        email: activeModal.data.email.value,
        password: activeModal.data.password.value,
        domain: landingData.domain,
        broker_id,
      })
      .then(({ data }) => {
        localStorage.setItem(Keys.JWT_TOKEN, data.access_token);

        dispatch(
          setUserData({
            role: data.role,
            avatar: data.profile_photo,
            token: data.access_token,
          }),
        );

        history.push('/onboarding/dashboard');
      })
      .catch((err) => setErrors(err?.response?.data?.error));
  }

  function showLogIn(show) {
    setActiveModal({
      show,
      data: {
        email: new InputParams('Email'),
        password: new InputParams('Password'),
      },
    });
  }

  function showApply() {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');
    setErrors('');

    if (token) {
      const jwtData = jwtDecode(token);
      if (jwtData && !jwtData.is_active) {
        showSetUp(true);
        setActiveModal((prev) => ({
          ...prev,
          show: 'set-up',
          data: {
            ...prev.data,
            firstName: {
              ...prev.data.firstName,
              value: jwtData.name,
            },
            lastName: {
              ...prev.data.lastName,
              value: jwtData.surname,
            },
            phone: {
              ...prev.data.phone,
              value: jwtData.phone,
            },
            email: {
              ...prev.data.email,
              value: jwtData.email,
            },
          },
        }));
      }
    } else {
      setActiveModal({
        show: 'set-up',
        data: {
          firstName: new InputParams('First Name'),
          lastName: new InputParams('Last Name'),
          phone: new InputParams('Phone'),
          email: new InputParams('Email'),
          email_confirmation: new InputParams('Email confirmation'),
          password: new InputParams('Password'),
        },
      });
    }
  }

  function showShare() {
    // TODO: Unhardcode this
    const emailTemplate =
      "Hi, I'm working with this as my mortgage broker for my investment property. I thought you might also be able to benefit from his real estate financing experience and express online loan application for investment properties.";

    setActiveModal({
      show: 'share',
      data: {
        link: new InputParams('Shareable link'),
        email: new InputParams('Share via Email', landingData.email),
        template: new InputParams('Share via Email', emailTemplate, undefined, 400),
      },
    });
  }

  function showSetUp(redirect, prevModal = 'log-in') {
    setActiveModal({
      show: 'set-up',
      redirect,
      prev_modal: prevModal,
      data: {
        email_confirmation: new InputParams('Email confirmation'),
        email: new InputParams('Email'),
        firstName: new InputParams('First Name'),
        lastName: new InputParams('Last Name'),
        password: new InputParams('Password'),
        phone: new InputParams('Phone'),
      },
    });
  }

  function closeModal() {
    setActiveModal({ show: '', data: null });
    setErrors('');
  }

  const handleOpenResetPassword = () => {
    setActiveModal({ show: 'reset-password', data: null });
  };

  const brandColour = landingData?.color_code || '#293BBC';

  const userInfoData = [
    {
      icon: (
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21C14.0993 20.763 10.4202 19.1065 7.65683 16.3432C4.8935 13.5798 3.23705 9.90074 3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4"
            stroke="#8C8C8C"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      ),
      text: (
        <a style={{ color: 'inherit' }} href={`tel:${landingData.direct_phone}`}>
          Mobile: {formatPhone(landingData.direct_phone)}
        </a>
      ),
      visible: landingData.direct_phone,
    },
    {
      icon: (
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5 4H9L11 9L8.5 10.5C9.57096 12.6715 11.3285 14.429 13.5 15.5L15 13L20 15V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21C14.0993 20.763 10.4202 19.1065 7.65683 16.3432C4.8935 13.5798 3.23705 9.90074 3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4"
            stroke="#8C8C8C"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      ),
      text: (
        <a style={{ color: 'inherit' }} href={`tel:${landingData.broker_work_phone}`}>
          Work Phone: {formatPhone(landingData.broker_work_phone)}
        </a>
      ),
      visible: landingData.broker_work_phone,
    },
    {
      icon: (
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
            stroke="#8C8C8C"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path d="M3 7L12 13L21 7" stroke="#8C8C8C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </svg>
      ),
      text: (
        <a style={{ color: 'inherit' }} href={`mailto:${landingData.email}`}>
          {landingData.email}
        </a>
      ),
      visible: landingData.email,
    },
  ];

  return (
    <>
      <BrokerLandingHeader
        brandColour={brandColour}
        desktop={innerWidth > 635}
        landingData={landingData}
        login={login}
        previewMode={previewMode}
        showApply={showApply}
        showLogIn={() => showLogIn('log-in')}
        showShare={showShare}
      />
      <div className={classes.main}>
        <div className={classes.thinBlueLine} style={{ backgroundColor: brandColour }} />
        <Spin spinning={!landingData.loading}>
          <div className={classes.dataWrapper}>
            <div className={classes.top}>
              {landingData?.broker_picture && (
                <div className={classes.pictureWrapper}>
                  <Image
                    alt=""
                    fallback={brokerPlaceholder}
                    src={landingData.broker_picture}
                    style={{ height: '100%' }}
                  />
                </div>
              )}
              <div className={classes.brokerInfo}>
                <div className={classes.decoration} />
                <h1>{landingData.broker_full_name}</h1>
                <span className="position" style={{ marginTop: 8 }}>
                  {landingData.company_short_name}
                </span>
                <ul className="user-data-list">
                  {userInfoData
                    .filter((el) => el.visible)
                    .map(({ icon, text }) => (
                      <li key={text.props.href}>
                        <div
                          align="center"
                          className="user-data-list__item"
                          style={{
                            '--hoverBg': landingData?.color_code || '#293BBC',
                            alignItems: 'center',
                            display: 'flex',
                            gap: 10,
                            marginBottom: 8,
                          }}
                        >
                          {icon}
                          <span
                            style={{
                              color: '#8C8C8C',
                              fontSize: 16,
                              marginBottom: 0,
                            }}
                          >
                            {text}
                          </span>
                        </div>
                      </li>
                    ))}
                </ul>
                {landingData.broker_NMLS && (
                  <div style={{ color: brandColour, fontSize: 14 }}>NMLS: Individual -{landingData.broker_NMLS}</div>
                )}
                {landingData.company_NMLS && (
                  <div style={{ color: brandColour, fontSize: 14 }}>NMLS: Company -{landingData.company_NMLS}</div>
                )}
              </div>
            </div>
            <div className={classes.bottomTile}>
              <form onSubmit={handleApplyBroker} className={classes.applyNow}>
                <h2>Apply now</h2>
                {Object.entries(activeSignIn).map((el, i) => {
                  const passwordVal = el[0].includes('password');
                  const inputTitle = el[0].includes('_')
                    ? capitalizeFirstLetter(el[0].split('_').join(' '))
                    : capitalizeFirstLetter(el[0]);

                  if (el[0] === 'phone') {
                    return (
                      <CustomPhoneInput
                        disabled={previewMode}
                        key={i}
                        title={inputTitle}
                        value={el[1].value}
                        onChange={(value) => {
                          setActiveSignIn((prevState) => ({
                            ...prevState,
                            [el[0]]: {
                              ...prevState[el[0]],
                              value,
                              error: null,
                            },
                          }));
                        }}
                        error={el[1].error}
                        errorMessage={el[1].errorMessage}
                      />
                    );
                  }
                  return (
                    <div className={`input_item ${el[1].full_line ? 'input_full' : ''}`} key={i}>
                      <CustomTextInput
                        autoComplete={passwordVal ? 'on' : null}
                        description={passwordVal ? el[1].error || 'At least 6 characters' : null}
                        disabled={previewMode}
                        error={el[1].error}
                        errorMessage={el[1].errorMessage}
                        htmlFor={el[0]}
                        maxlength={255}
                        onChange={(e) => onApplyNowChange(el, e)}
                        showPassIcon={passwordVal}
                        title={inputTitle}
                        type={passwordVal ? 'password' : null}
                        value={el[1].value}
                      />
                    </div>
                  );
                })}
                <div className="input_item input_full">
                  <BrokerLandingConsentLinks links={landingLinks} />
                </div>
                {!activeModal.show && errors && <Typography.Text type="danger">{errors}</Typography.Text>}
                <div style={{ gridColumn: '1/3' }}>
                  <CustomButton
                    style={{ background: brandColour, borderColor: brandColour }}
                    text="Continue"
                    className="btn_blue"
                    disabled={previewMode}
                    type="submit"
                  />
                </div>
              </form>
              <div className={classes.signIn}>
                <div>
                  <h2>Sign In if you already have an account</h2>
                  <CustomButton
                    className="btn_blue"
                    disabled={previewMode}
                    onClick={() => showLogIn('log-in')}
                    style={{ background: brandColour, borderColor: brandColour }}
                    text="Sign In"
                  />
                </div>
                <div>
                  <b>
                    With our express process you can submit your commercial real estate loan application online in 15
                    minutes.
                  </b>
                  <ul>
                    {bulletPoints.map((text) => (
                      <li key={text}>
                        <svg
                          height="24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          style={{ height: '1.5em', verticalAlign: 'text-bottom' }}
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            fill={brandColour}
                          />
                          <path d="M9 12L11 14L15 10" stroke="#fdfdff" />
                        </svg>
                        <span>{text}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
      <BrokerLandingPopup
        width={370}
        open={activeModal.show === 'log-in' || activeModal.show === 'apply_now'}
        color={landingData.color_code}
        style={innerWidth > 635 ? { top: 84, margin: '0 calc(12.5% + 150px) 0 auto' } : null}
        onClose={closeModal}
        footer={
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Button
                block
                size="large"
                type="primary"
                style={{
                  borderColor: landingData.color_code,
                  backgroundColor: landingData.color_code,
                }}
                onClick={logIn}
              >
                Sign In
              </Button>
            </Col>
            <Col span={24}>
              <Button
                block
                type="link"
                size="large"
                style={{
                  color: landingData.color_code,
                }}
                onClick={() => showSetUp(true, activeModal.show)}
              >
                I don't have an account
              </Button>
            </Col>
            <Col span={24}>
              <Button
                block
                type="link"
                size="large"
                style={{
                  color: landingData.color_code,
                }}
                onClick={handleOpenResetPassword}
              >
                Reset Password
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="broker-landing-popup-content">
          <SectionTitle
            style={{ display: 'block', marginBottom: 22 }}
            title={activeModal.show === 'log-in' ? 'Sign In' : 'Apply now'}
            type="regular-24"
          />
          <TextInput {...activeModal?.data?.email} htmlFor="email" onChange={onChangeModal} />
          <TextInput
            {...activeModal?.data?.password}
            htmlFor="password"
            onChange={onChangeModal}
            showPassIcon
            type="password"
          />
          {activeModal?.data?.responseError && (
            <p style={{ marginBottom: 16 }} className="errorMessage">
              Email or password is not correct
            </p>
          )}
        </div>
      </BrokerLandingPopup>
      <BrokerLandingPopup
        centered
        color={landingData.color_code}
        footer={false}
        onClose={closeModal}
        submitFunc={sendShare}
        visible={activeModal.show === 'share'}
        width={543}
      >
        <div className="broker-landing-popup-content">
          <SectionTitle
            style={{ marginBottom: 16, display: 'block' }}
            title="Share broker's landing page"
            type="heading-20"
          />
          <TextInput
            {...activeModal?.data?.link}
            disabled
            htmlFor="link"
            icon={copy}
            iconClick={() => {
              message.success('Link is copied to clipboard');
              navigator.clipboard.writeText(activeModal?.data?.link?.value);
            }}
            onChange={onChangeModal}
          />
          <TextInput {...activeModal?.data?.email} htmlFor="email" onChange={onChangeModal} />
          <TextArea {...activeModal?.data?.template} disabled htmlFor="template" label="Message" />
        </div>
        <div className="broker-landing-popup-row-footer" style={{ marginTop: 20 }}>
          <CustomButton
            className="btn_darkblue"
            key="submit"
            style={{ width: 108, background: brandColour, borderColor: brandColour }}
            text="Send"
            type="submit"
          />
          <CustomButton key="back" onClick={closeModal} style={{ width: 108 }} text="Cancel" />
        </div>
      </BrokerLandingPopup>
      <BrokerLandingPopup
        borderColor="#d9d9d9"
        cancelFunc={() => showLogIn('log-in')}
        cancelText="Already have an account? Sign In"
        color={landingData.color_code}
        submitText="Continue"
        style={innerWidth > 635 ? { top: 84, margin: '0 calc(12.5% + 270px) 0 auto' } : null}
        onClose={closeModal}
        submitFunc={setUp}
        visible={activeModal.show === 'set-up'}
        width={370}
      >
        <div className="broker-landing-popup-content">
          <SectionTitle style={{ marginBottom: 14, display: 'block' }} title="Set up a new account" type="regular-24" />
          <SectionTitle
            style={{ marginBottom: 15, display: 'block', color: '#595959' }}
            title="Create an account to start"
            type="body-14"
          />
          <div className="split-input">
            <TextInput {...activeModal?.data?.firstName} htmlFor="firstName" onChange={onChangeModal} />
            <TextInput {...activeModal?.data?.lastName} htmlFor="lastName" onChange={onChangeModal} />
          </div>
          <CustomPhoneInput
            {...activeModal?.data?.phone}
            name="phone"
            onChange={onChangeModal}
            maxLength={10}
            isObjectEvent
            rules={[{ ...formRules.phone, required: true }]}
          />
          <TextInput {...activeModal?.data?.email} htmlFor="email" onChange={onChangeModal} />
          <TextInput {...activeModal?.data?.email_confirmation} htmlFor="email_confirmation" onChange={onChangeModal} />
          <TextInput
            {...activeModal?.data?.password}
            description={activeModal?.data?.password?.error || 'At least 6 characters'}
            htmlFor="password"
            onChange={onChangeModal}
            showPassIcon
            type="password"
          />
          {errors && <Typography.Text type="danger">{errors}</Typography.Text>}
          <BrokerLandingConsentLinks links={landingLinks} />
        </div>
      </BrokerLandingPopup>
      <ResetPasswordPopup
        color={landingData.color_code}
        isOpen={activeModal.show === 'reset-password'}
        onCancel={() => setActiveModal({ show: '', data: null })}
      />
    </>
  );
}
