import { Badge, Modal, Table, Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import API from 'api';
import crossIcon from 'assets/images/cross.svg';
import globalStyles from 'constants/globalStyles';
import PaginationItemRender from 'constants/PaginationItemRender';
import { replacedTextData } from 'constants/common';
import plusIcon from 'assets/images/plus.svg';
import CustomButton from 'components/UI/Button';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import UserDrawer from 'components/UserDrawer';

const defaultTabs = ['All', 'Mortgage Loan Officer', 'Broker Transaction Manager'];

const supportRoles = [
  'Broker Loan Manager',
  'Broker Transaction Manager',
  'Lender Account Executive',
  'Lender Transaction Manager',
];

const activeModalInitialState = {
  data: null,
  show: null,
};

export default function CompanyMembers({ tabs = defaultTabs, isCompanyAdmin }) {
  const history = useHistory();
  const location = useLocation();
  const [activeModal, setActiveModal] = useState(activeModalInitialState);
  const [members, setMembers] = useState({ loading: true });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [paginationTotal, setPaginationTotal] = useState(1);
  const [activeTab, setActiveTab] = useState(location.state?.tab || 'All');
  const { userData } = useSelector((store) => store.user);

  useEffect(() => {
    const params = {
      page: pagination.current,
      per_page: pagination.pageSize,
    };

    if (activeTab !== 'All') {
      params.role_name = activeTab === 'Mortgage Loan Officer' ? 'Broker Loan Manager' : activeTab;
    }

    API()
      .get('/user_settings/company/members', { params })
      .then(({ data }) => {
        setPaginationTotal(data.total);
        setMembers({
          ...data,
          loading: false,
        });
      });
  }, [activeTab, pagination]);

  const onTabChange = useCallback(
    (val) => {
      setActiveTab(val);
      setPagination((state) => ({
        ...state,
        current: 1,
      }));
      history.push({
        state: { tab: val },
      });
    },
    [history],
  );

  const columns = useMemo(
    () => [
      {
        dataIndex: 'full_name',
        key: 'full_name',
        render: (val, item) =>
          userData.id === item.id ? (
            <Badge
              dot
              style={{
                background: globalStyles.main_blue_color,
                height: 12,
                minWidth: 12,
                right: -10,
                top: 0,
                width: 12,
              }}
            >
              {val}
            </Badge>
          ) : (
            val
          ),
        title: 'Full Name',
        width: 200,
      },
      {
        dataIndex: 'role',
        key: 'role',
        title: 'Role',
        width: 200,
        render: (_, { role }) => replacedTextData[role] || role,
      },
      {
        dataIndex: 'email',
        key: 'email',
        title: 'Email',
        width: 250,
      },
      {
        dataIndex: 'last_login',
        key: 'last_login',
        title: 'Last login',
        width: 250,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 250,
      },
      {
        render: (val, data) =>
          isCompanyAdmin && (
            <img
              alt="x"
              onClick={(e) => {
                e.stopPropagation();
                setActiveModal({ show: 'delete', data });
              }}
              src={crossIcon}
              style={{ marginLeft: 'auto', display: 'block' }}
            />
          ),
        width: 24,
      },
    ],
    [isCompanyAdmin, userData.id],
  );

  const onDelete = useCallback((id) => {
    API()
      .delete('/user_settings/company/members', { params: { id } })
      .then(() => {
        setMembers((state) => ({
          ...state,
          members: state.members.filter((i) => i.id !== id),
        }));
        setActiveModal(activeModalInitialState);
      });
  }, []);

  const getUserData = useCallback((id) => {
    API()
      .get('/user_settings/company/members', { params: { id } })
      .then(({ data }) => {
        setActiveModal({ show: 'member', data: data.user });
      });
  }, []);

  return (
    <div className="table_with_header_wrapper table_with_pagination_wrapper company-members">
      <div className="section_title_wrapper">
        <SectionTitle title="Colleagues" />
        {isCompanyAdmin && (
          <CustomButton
            className="btn_darkblue"
            img={plusIcon}
            onClick={() => {
              const part = location.pathname.split('/').pop();
              history.push(`/onboarding/${part}/associate_new_members`);
            }}
            text="Associate new team members"
          />
        )}
      </div>
      <div className="section_table_wrapper table_with_header table_with_pagination">
        <Table
          border
          bordered={false}
          columns={columns}
          dataSource={members.members}
          loading={members.loading}
          onChange={(p) => {
            setPaginationTotal(p.total);
            delete p.total;
            setPagination(p);
          }}
          onRow={(record) => ({
            onClick: () => {
              if (supportRoles.includes(userData.role)) {
                getUserData(record.id);
              } else {
                history.push({
                  pathname: `/onboarding/users/${record.id}`,
                  state: { tab: activeTab },
                });
              }
            },
          })}
          pagination={{
            current: pagination.current,
            itemRender: PaginationItemRender,
            pageSize: pagination.pageSize,
            showSizeChanger: true,
            total: paginationTotal,
          }}
          rowKey="id"
          scroll={{ x: 1100 }}
          title={() => (
            <Tabs
              defaultActiveKey={activeTab}
              activeKey={activeTab === 'Broker Loan Manager' ? 'Mortgage Loan Officer' : activeTab}
              onChange={onTabChange}
            >
              {tabs.map((i) => (
                <Tabs.TabPane tab={replacedTextData[i] || i} key={i} />
              ))}
            </Tabs>
          )}
        />
      </div>
      <UserDrawer
        onClose={() => setActiveModal(activeModalInitialState)}
        user={activeModal.data}
        visible={activeModal.show === 'member'}
      />
      <Modal
        centered
        className="wrapper_prospect wrapper_prospect_delete"
        footer={
          <div className="wrapper_remove_prospect_btns">
            <CustomButton key="back" onClick={() => setActiveModal(activeModalInitialState)} text="Cancel" />
            <CustomButton
              className="btn_darkblue"
              key="submit"
              onClick={() => onDelete(activeModal.data.id)}
              text="Confirm"
            />
          </div>
        }
        onCancel={() => setActiveModal(activeModalInitialState)}
        onOk={() => setActiveModal(activeModalInitialState)}
        title={
          <div className="wrapper_prospect__title">
            <span className="title">Delete user account</span>
            <span className="description">
              Are you sure you want to delete this user's account?
              <br />
              All LARs and related users will be transferred to the Admin when this user is disassociated
            </span>
          </div>
        }
        open={activeModal.show === 'delete'}
      />
    </div>
  );
}
