import { Breadcrumb, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { useLARDBContext } from 'pages/Onboarding/LARDB/hooks/LARDBContext';
import { NoteIcon } from 'assets/icons';
import globalStyles from 'constants/globalStyles';
import ArchivalReasonPlaque from 'components/ArchivalReasonPlaque';
import TeamMembersPopover from 'components/TeamMembersPopover';
import RightLeftButtons from './RightLeftButtons';
import styles from './styles/LARDBHeader.module.css';

function LARDBHeader({ openNoteDrawer, showInfo, toggleShowInfo }) {
  const {
    lar_id: larId,
    lar_members: larMembers,
    lar_status: status,
    property_address: address,
    refresh = () => null,
    reject_reason: archivalReason,
  } = useLARDBContext();
  const location = useLocation();

  return (
    <div className={styles.root}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/onboarding/dashboard">Dashboard</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={location.pathname}>{`#${larId}`}</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <ArchivalReasonPlaque id={larId} onUnarchive={refresh} reason={archivalReason} />
      <div className={styles.leftPart}>
        <h1>{address}</h1>
        <div>{status}</div>
        <Button
          icon={<RightOutlined rotate={showInfo ? -90 : 90} style={{ color: globalStyles.main_blue_color }} />}
          onClick={toggleShowInfo}
        />
      </div>
      <div className={styles.rightPart}>
        <TeamMembersPopover larMembers={larMembers} larId={larId} />
        <div>
          <Button className={styles.notesButton} onClick={openNoteDrawer}>
            Comment <NoteIcon size="1.4em" />
          </Button>
        </div>
        <RightLeftButtons larId={larId} />
      </div>
    </div>
  );
}

export default LARDBHeader;
