import '../../BDR/styles/prospects.css';
import { Breadcrumb, Drawer, Form, message, Table, Tabs } from 'antd';
import { numberToCurrency } from 'constants/numberToComma';
import { useHistory, useParams } from 'react-router-dom';
import AdminReassignModal from 'components/AdminReassignModal';
import API from 'api';
import arrowRight from 'assets/images/arrow-right.svg';
import copyIcon from 'assets/images/copy.svg';
import CustomAvatar from 'components/UI/Avatar';
import CustomButton from 'components/UI/Button';
import PaginationItemRender from 'constants/PaginationItemRender';
import React, { useEffect, useMemo, useState } from 'react';
import SectionTitle from 'components/OnBoarding/SectionTitle';

import styles from './Company.module.css';
import { CheckOutlined } from '@ant-design/icons';
import { PencilIcon } from 'assets/icons';
import CompanyEdit from './CompanyEdit';

function Company() {
  const history = useHistory();
  const { id } = useParams();
  const [company, setCompany] = useState({ loading: false });
  const [companyType, setCompanyType] = useState('Brokers');
  const [activeTab, setActiveTab] = useState(history?.location?.state?.companyTab || 'Users');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const [shouldShowAdminModal, setShouldShowAdminModal] = useState(false);

  const onCloseActiveModal = () => setActiveModal((state) => ({ ...state, show: false }));

  const onOpenActiveModal = () => setActiveModal((state) => ({ ...state, show: true }));

  const brokerColumns = {
    Borrowers: [
      {
        title: 'User name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Recent Sign In',
        dataIndex: 'last_login',
        key: 'last_login',
      },
      {
        title: 'Number of LARs',
        dataIndex: 'number_of_lars',
        key: 'number_of_lars',
      },
    ],
    Users: [
      {
        title: 'User name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Recent Sign In',
        dataIndex: 'last_login',
        key: 'last_login',
      },
      {
        title: 'Number of LARs',
        dataIndex: 'number_of_lars',
        key: 'number_of_lars',
      },
      {
        title: 'Number of Borrowers',
        dataIndex: 'number_of_borrower',
        key: 'number_of_borrower',
      },
    ],
    LARs: [
      {
        title: 'LAR ID',
        dataIndex: 'id',
        key: 'id',
        width: 125,
        render: (value) => (
          <span className="id-column">
            #{value}
            <img
              src={copyIcon}
              onClick={() => {
                message.success('Text was copied to clipboard.');
                navigator.clipboard.writeText(value);
              }}
              alt=""
            />
          </span>
        ),
      },
      {
        title: 'LAR Status',
        dataIndex: 'status',
        key: 'status',
        width: 150,
      },
      {
        title: 'LAE Name',
        dataIndex: 'lae_name',
        key: 'lae_name',
        width: 150,
      },
      {
        title: 'LAE Recent Login',
        dataIndex: 'lar_last_login',
        key: 'lar_last_login',
        width: 200,
      },
      {
        title: 'Loan Amount',
        dataIndex: 'loan_amount',
        key: 'loan_amount',
        width: 150,
        render: (value) => numberToCurrency(value),
      },
      {
        title: 'Broker Name',
        dataIndex: 'broker_name',
        key: 'broker_name',
        width: 150,
      },
      {
        title: 'Borrower Name',
        dataIndex: 'borrower_name',
        key: 'borrower_name',
        width: 150,
      },
    ],
  };
  const lenderColumns = {
    LARs: [
      {
        title: 'LAR ID',
        dataIndex: 'id',
        key: 'id',
        width: 125,
        render: (value) => (
          <span className="id-column">
            #{value}
            <img
              src={copyIcon}
              onClick={() => {
                message.success('Text was copied to clipboard.');
                navigator.clipboard.writeText(value);
              }}
              alt=""
            />
          </span>
        ),
      },
      {
        title: 'LAR Status',
        dataIndex: 'status',
        key: 'status',
        width: 150,
      },
      {
        title: 'LAE Name',
        dataIndex: 'lae_name',
        key: 'lae_name',
        width: 150,
      },
      {
        title: 'LAE Recent Login',
        dataIndex: 'lar_last_login',
        key: 'lar_last_login',
        width: 200,
      },
      {
        title: 'Loan Amount',
        dataIndex: 'loan_amount',
        key: 'loan_amount',
        width: 150,
        render: (value) => numberToCurrency(value),
      },
      {
        title: 'Broker Name',
        dataIndex: 'broker_name',
        key: 'broker_name',
        width: 150,
      },
      {
        title: 'Borrower Name',
        dataIndex: 'borrower_name',
        key: 'borrower_name',
        width: 150,
      },
    ],
    Users: [
      {
        title: 'User name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Recent Sign In',
        dataIndex: 'last_login',
        key: 'last_login',
      },
      {
        title: 'Number of LARs',
        dataIndex: 'number_of_lars',
        key: 'number_of_lars',
      },
    ],
    Mandates: [
      {
        title: 'Mandate name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Active/Not active',
        dataIndex: 'status',
        key: 'status',
        render: (bool) => (bool ? 'Active' : 'Not active'),
      },
      {
        title: 'Assigned to',
        dataIndex: 'LAE_assigned',
        key: 'LAE_assigned',
      },
      {
        title: 'Recent update',
        dataIndex: 'updated_at',
        key: 'updated_at',
      },
    ],
  };

  const tabCallback = (val) => {
    setActiveTab(val);
    setPagination((state) => ({
      ...state,
      current: 1,
    }));
    history.push({
      state: { ...history.location.state, companyTab: val },
    });
  };

  const columns = useMemo(
    () => (companyType === 'Brokers' ? brokerColumns : companyType === 'Lenders' ? lenderColumns : {}),
    // eslint-disable-next-line
    [companyType],
  );

  const goBack = () => {
    history.push({
      pathname: '/onboarding/companies/',
      state: { ...history.location.state, companyTab: activeTab },
    });
  };

  const getCompanyData = () => {
    API()
      .get('/superadmin/company', {
        params: {
          page: pagination.current,
          per_page: pagination.pageSize,
          section: activeTab.toLowerCase(),
          company_id: id,
        },
      })
      .then((res) => {
        setPagination((prevState) => ({ ...prevState, total: res.data.total }));
        setCompany({
          loading: true,
          total: res.data.total,
          name: res.data.name,
          members: res?.data?.members || [],
          mandates: res?.data?.mandates || [],
          lars: res?.data?.lars || [],
          company_data: res.data.company_data,
        });
        setCompanyType(() => {
          switch (undefined) {
            case res.data.company_data.investments:
              return 'Brokers';

            case res.data.company_data.brokerage_type:
              return 'Lenders';

            default:
              return undefined;
          }
        });
      });
  };

  useEffect(() => {
    setCompany((prevState) => ({ loading: false, name: prevState.name }));
    getCompanyData();
    // eslint-disable-next-line
  }, [activeTab, pagination.current, pagination.pageSize]);

  function CompanyDetailsRow({ title, value }) {
    return (
      <div className={styles.companyDetailsRowContainer}>
        <p className={styles.companyDetailsRowTitle}>{title}</p>
        <p className={styles.companyDetailsRowValue}>{value || 'No info'}</p>
      </div>
    );
  }

  const profileButtons = [
    { name: 'View Mandates', id: 0 },
    { name: 'View Users', id: 1 },
    { name: 'View LARs', id: 2 },
  ];

  const [isEdit, setIsEdit] = useState(false);

  const [form] = Form.useForm();

  return (
    <>
      <div className="prospects_page_wrapper table_with_header_wrapper table_with_pagination_wrapper">
        <Breadcrumb style={{ marginBottom: 15 }}>
          <Breadcrumb.Item
            href=""
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
          >
            Companies
          </Breadcrumb.Item>
          <Breadcrumb.Item>{company?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="section_title_wrapper">
          <SectionTitle title={company?.name} />
          <div
            style={{
              marginTop: 7,
              marginLeft: 10,
            }}
            className="user-preview-wrapper"
          >
            <button onClick={onOpenActiveModal}>View Profile</button>
          </div>
        </div>
        <div className="section_table_wrapper table_with_header table_with_pagination">
          <Table
            columns={columns[activeTab]}
            loading={!company.loading}
            pagination={{
              showSizeChanger: true,
              defaultCurrent: pagination.current,
              total: pagination.total ?? null,
              itemRender: PaginationItemRender,
            }}
            onChange={(e) => setPagination(e)}
            scroll={{ x: 1500 }}
            rowKey="id"
            title={() => (
              <Tabs defaultActiveKey={activeTab} onChange={tabCallback}>
                {companyType === 'Brokers' ? (
                  <>
                    <Tabs.TabPane tab="Brokers" key="Users" />
                    <Tabs.TabPane tab="Borrowers" key="Borrowers" />
                    <Tabs.TabPane tab="LARs" key="LARs" />
                  </>
                ) : (
                  <>
                    <Tabs.TabPane tab="Lenders" key="Users" />
                    <Tabs.TabPane tab="LARs" key="LARs" />
                    <Tabs.TabPane tab="Mandates" key="Mandates" />
                  </>
                )}
              </Tabs>
            )}
            onRow={(record) => ({
              onClick: () => {
                if (activeTab === 'Users') {
                  history.push({
                    pathname: `/onboarding/companies/${id}/user/${record.id}`,
                    state: {
                      ...history.location.state,
                      companyTab: activeTab,
                      companyName: company?.name,
                    },
                  });
                }
              },
            })}
            border
            bordered={false}
            dataSource={
              activeTab === 'Mandates' ? company?.mandates : activeTab === 'LARs' ? company?.lars : company?.members
            }
          />
        </div>
      </div>
      <Drawer
        placement="right"
        onClose={onCloseActiveModal}
        closeIcon={false}
        bodyStyle={{ padding: 0 }}
        className={styles.drawer}
        width={360}
        visible={activeModal.show}
        headerStyle={{ display: 'none' }}
      >
        <div className={styles.drawerWrapper}>
          <div className={styles.drawerHeader}>
            <img onClick={onCloseActiveModal} src={arrowRight} alt="" />
            <p className={styles.headerTitle}>Company Profile</p>
            <div
              onClick={(e) => {
                e.stopPropagation();
                setIsEdit(false);
              }}
              className={styles.buttonEdit}
            >
              {isEdit ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsEdit(false);
                    form.submit();
                  }}
                  className={styles.buttonEditOn}
                >
                  <span className={styles.buttonEditOnTitle}>Save</span>
                  <CheckOutlined size={24} className={styles.buttonEditOnIcon} />
                </div>
              ) : (
                <PencilIcon />
              )}
            </div>
          </div>
          {isEdit ? (
            <CompanyEdit form={form} />
          ) : (
            <>
              <div className={styles.section}>
                <div className={styles.drawerCompanyInfo}>
                  <CustomAvatar src={company?.company_data?.logo} size={72} />
                  <div className={styles.drawerCompanyDetails}>
                    <p className={styles.companyDetaisTitle}>{company?.company_data?.name}</p>
                    <p className={styles.companyDetaisSubTitle}>Created: 12 May 2024</p>
                  </div>
                </div>
                <div className={styles.divider} />
                <div className={styles.containerButtons}>
                  {profileButtons.map((el) => (
                    <CustomButton
                      className={styles.customBtn}
                      onClick={() => setShouldShowAdminModal(true)}
                      text={el.name}
                      key={el.id}
                    />
                  ))}
                </div>
                <div className={styles.divider} />
                <div className={styles.rowFlex}>
                  <CompanyDetailsRow title="Account Owner" value="David Libman" />
                  <CompanyDetailsRow title="Referred by:" value="Leon Cooper" />
                </div>
              </div>
              <div className={styles.section}>
                <p className={styles.titleUpperCase}>company admin:</p>
                <div className={styles.drawerCompanyInfo}>
                  <CustomAvatar
                    src={company?.company_data?.admin?.profile_photo}
                    fullName={company?.company_data?.admin?.full_name}
                    size={56}
                  />
                  <div className={styles.drawerCompanyDetails}>
                    <p className={styles.companyAdminTitle}>{company?.company_data?.admin?.full_name}</p>
                    <CustomButton
                      className={styles.customBtn}
                      onClick={() => setShouldShowAdminModal(true)}
                      text="Update Admin"
                    />
                  </div>
                </div>
                <div className={styles.divider} />
                <div className={styles.companyAdminInfo}>
                  <p className={styles.companyDetailsRowValue}>{company?.company_data?.admin?.phone}</p>
                  <p className={styles.companyDetailsRowValue}>{company?.company_data?.admin?.email}</p>
                </div>
              </div>
              <div className={styles.section}>
                <p className={styles.titleUpperCase}>company details:</p>
                <CompanyDetailsRow title="Company role:" value="Fulfillment" />
                <div className={styles.divider} />
                <CompanyDetailsRow title="Company type:" value="Commercial Lender" />
                <div className={styles.divider} />
                <CompanyDetailsRow title="Company website:" value="www.lenderco.com" />
                <div className={styles.divider} />
                <CompanyDetailsRow
                  title="Location:"
                  value={
                    <p className={styles.locationInfo}>
                      <p>Street address 1</p>
                      <p>Street address 2</p>
                      <span>
                        Los Angeles <span>•</span> California <span>•</span> 80000
                      </span>
                    </p>
                  }
                />
              </div>
            </>
          )}
          {/* <div className="drawer-body company">
              <div>
                <UniqueData
                  value={company?.company_data?.phone ? formatPhone(company?.company_data?.phone) : '...'}
                  label="Mobile Phone:"
                />
              </div>
              <div>
                <UniqueData
                  value={company?.company_data?.office_phone ? formatPhone(company?.company_data?.office_phone) : '...'}
                  label="Office phone:"
                />
              </div>
              <div>
                <UniqueData value="..." link={company?.company_data?.website} label="Broker website:" />
              </div>
              <div>
                <UniqueData value={company?.company_data?.nmls ? company?.company_data?.nmls : '...'} label="NMLS:" />
              </div>
              <div className="wide">
                <UniqueData value={company?.company_data?.street_address ?? '...'} label="Address:" />
              </div>
              <div className="wide">
                <UniqueData
                  value={
                    [company?.company_data?.city, company?.company_data?.state].filter((i) => i).join(', ') || '...'
                  }
                  label="State, City:"
                />
              </div>
              <div className="wide">
                <UniqueData value={company?.company_data?.zip ?? '...'} label="Zip Code:" />
              </div>
            </div> */}
        </div>
      </Drawer>
      <AdminReassignModal
        companyId={id}
        companyName={company?.company_data?.name}
        onCancel={() => setShouldShowAdminModal(false)}
        reqRole={companyType === 'Brokers' ? 'broker' : 'lender'}
        visible={shouldShowAdminModal}
        onAfterReassign={() => {
          setShouldShowAdminModal(false);
          getCompanyData();
          onCloseActiveModal();
        }}
      />
    </>
  );
}

export default Company;
