import { Card, Col, Divider, Row, Typography } from 'antd';

export default function LoanProfileRefinance({ data = {} }) {
  const list = [
    { title: 'Refinance Goals', value: data.refinance_goals },
    { title: 'Refi Renovation Budget', value: data.refi_renovation_budget },
    { title: 'Cash-Out Requested Amount', value: data.cash_out_requested_amount },
    { title: 'Refi ARV', value: data.refi_arv },
    {
      title: 'Current Mortgage Payment',
      value: data.current_mortgage_payment ? `$${data.current_mortgage_payment?.toLocaleString()}` : null,
    },
    { title: 'Additional Debt Capital Sources', value: data.additional_dept_capital_sources },
    { title: 'Payment Type', value: data.payment_type },
  ];

  return (
    <Card>
      <Typography.Title level={4}>Refinance</Typography.Title>
      <Divider style={{ margin: '0 0 10px 0' }} />
      <Row gutter={[24, 8]}>
        {list
          .filter((i) => i.value !== null)
          .map((item, index) => (
            <Col span={12} key={index}>
              <Row justify="space-between">
                <Col>
                  <Typography.Text type="secondary">{item.title}</Typography.Text>
                </Col>
                <Col>{item.value}</Col>
              </Row>
            </Col>
          ))}
      </Row>
    </Card>
  );
}
