import { Drawer, Form } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { formatPhone } from 'constants/formatPhone';
import { replacedTextData } from 'constants/common';
import CustomAvatar from 'components/UI/Avatar';
import arrowRight from 'assets/images/arrow-right.svg';
import styles from './UserDrawer.module.css';
import CustomButton from 'components/UI/Button/Button';
import { PencilIcon } from 'assets/icons';
import { useState } from 'react';
import UserDrawerEdit from './UserDrawerEdit';

function UserDrawer({ user, visible, onClose }) {
  if (isEmpty(user)) {
    return null;
  }

  // const lastOnline = new Date(user.last_online).toLocaleString();

  function DetailsRow({ title, value }) {
    return (
      <div className={styles.companyDetailsRowContainer}>
        <p className={styles.companyDetailsRowTitle}>{title}</p>
        <p className={styles.companyDetailsRowValue}>{value || 'No info'}</p>
      </div>
    );
  }

  const [isEdit, setIsEdit] = useState(false);

  const [form] = Form.useForm();

  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      className={styles.drawer}
      placement="right"
      open={visible}
      width={360}
      headerStyle={{ display: 'none' }}
    >
      <div className={styles.drawerWrapper}>
        <div className={styles.drawerHeader}>
          <img style={{ width: 24 }} onClick={onClose} src={arrowRight} alt="" />
          <p className={styles.headerTitle}>User Profile</p>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsEdit((prev) => !prev);
            }}
            className={styles.buttonEdit}
          >
            {isEdit ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setIsEdit((prev) => !prev);
                  form.submit();
                }}
                className={styles.buttonEditOn}
              >
                <span className={styles.buttonEditOnTitle}>Save</span>
                <CheckOutlined size={24} className={styles.buttonEditOnIcon} />
              </div>
            ) : (
              <PencilIcon />
            )}
          </div>
        </div>
        {isEdit ? (
          <UserDrawerEdit form={form} user={user} />
        ) : (
          <>
            <div className={styles.section}>
              <div className={styles.drawerCompanyInfo}>
                <CustomAvatar fullName={user.full_name} src={user.avatar} size={72} />
                <div className={styles.drawerCompanyDetails}>
                  <p className={styles.companyDetaisTitle}>{user.full_name}</p>
                  <p className={styles.companyDetaisSubTitle}>{replacedTextData[user.role] || user.role}</p>
                  <p className={styles.companyDetaisSubTitle}>Disabled</p>
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <p className={styles.titleUpperCase}>Company:</p>
              <div className={styles.drawerCompanyInfo}>
                <CustomAvatar src={user.avatar} size={56} />
                <div className={styles.drawerCompanyDetails}>
                  <p className={styles.companyAdminTitle}>{user.company_name}</p>
                  <CustomButton
                    className={styles.customBtn}
                    // onClick={() => setShouldShowAdminModal(true)}
                    text="See Details"
                  />
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <p className={styles.titleUpperCase}>company details:</p>
              <DetailsRow title="Business email" value={user.email} />
              <div className={styles.divider} />
              <DetailsRow title="Work phone" />
              <div className={styles.divider} />
              <DetailsRow title="Mobile Phone" value={formatPhone(`+1${user.phone}`)} />
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
}

export default UserDrawer;
