import { Badge, Form, Input } from 'antd';
import CustomAvatar from 'components/UI/Avatar/Avatar';
// import CustomButton from 'components/UI/Button/Button';
import { acceptedMimes } from 'constants/mimes';
import { useRef } from 'react';
import formRules from 'constants/formRules';

import styles from './UserDrawer.module.css';
import { PencilIcon } from 'assets/icons';
// import API from 'api';

function UserDrawerEdit({ form, user }) {
  const uploadRef = useRef(null);

  // const [avatarCropModal, setAvatarCropModal] = useState({ visible: false, file: null });
  // const [pic, setPic] = useState({ file: null, thumb: null });
  // const [profileData, setProfileData] = useState({});

  // function handleSelectPic() {
  //   setAvatarCropModal({ visible: true, file: uploadRef.current.files[0] });
  // }

  // function handleCroppedPhoto(blob, dataURL) {
  //   setPic({
  //     file: blob,
  //     thumb: dataURL,
  //   });

  //   setAvatarCropModal((prev) => ({ ...prev, visible: false }));
  // }
  // function resetFileInput() {
  //   uploadRef.current.value = null;
  // }

  // console.log(user.id, 'user');

  // const handleSave = (data) => {
  //   API()
  //     .post('/superadmin/user', data)
  //     .then((res) => {
  //       console.log(res);
  //     });
  //   console.log(data);
  // };

  // useEffect(() => {
  //   getUserData();
  // }, []);

  return (
    <div>
      <Form
        className={styles.form}
        form={form}
        initialValues={{}}
        layout="vertical"
        onFinish={(values) => {
          console.log(values);

          // const newData = {
          //   name: values.full_name || user.name,
          //   id: user.id,
          //   email: values.email || user.email,
          //   role_name: user.role,
          //   surname: user.surname || '',
          // };
          // handleSave(newData);
        }}
        requiredMark={false}
      >
        <div className={styles.section}>
          <div className={styles.avatarWrapper}>
            <input
              hidden
              type="file"
              ref={uploadRef}
              accept={acceptedMimes.avatar}
              // onChange={handleSelectPic}
              // onClick={resetFileInput}
            />
            <Badge count={<PencilIcon colour="white" />} onClick={() => uploadRef.current?.click()}>
              <CustomAvatar
                // name={profileData.name}
                // src={pic.thumb || profileData.avatar || profileData.profile_photo}
                // surname={profileData.surname}
                size={72}
              />
            </Badge>
          </div>
          <div>
            <p className={styles.usernameTitle}>Leon Cooper</p>
            <p className={styles.subTitle} style={{ marginBottom: 8 }}>
              Created: 12 May 2024
            </p>
            <p className={styles.subTitle}>Broker Loan Manager</p>
            <p className={styles.subTitle}>Disabled</p>
          </div>
        </div>
        <div className={styles.section}>
          <Form.Item
            label="Full Name"
            name="full_name"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input defaultValue={user.full_name} />
          </Form.Item>
          {/* <Form.Item
            label="Last name"
            name="surname"
            rules={[{ required: true, message: 'Please input your last name!' }]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item label="Business email" name="email" rules={[{ ...formRules.email, required: true }]}>
            <Input defaultValue={user.email} />
          </Form.Item>
          <Form.Item label="Work phone" name="work_phone" rules={[formRules.phone]} validateTrigger="onSubmit">
            <Input addonBefore="+1" maxLength={10} />
          </Form.Item>
          <Form.Item
            label="Mobile phone"
            name="phone"
            validateTrigger="onSubmit"
            rules={[{ ...formRules.phone, required: true }]}
          >
            <Input defaultValue={user.phone} addonBefore="+1" maxLength={10} />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default UserDrawerEdit;
