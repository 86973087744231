import { BinIcon, HorizontalDotsIcon, NoteIcon } from 'assets/icons';
import { Dropdown, Tooltip, message, Typography } from 'antd';
import LegacyApi, { LARAPI, SettingsAPI } from 'api';
import { useCallback, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useLarApi from '../../../LarAppNew/hooks/useLarApi';
import Modal from 'components/Modals/Modal/Modal';
import WithdrawModal from 'components/LARDB/Settings/WithdrawModal';
import NoResults from '../../../LarAppNew/Modals/EndScenarioModal/NoResults';
import alertTriangle from 'assets/icons/alert-triangle-yellow.svg';
import DelayArchivePopup from '../../DelayArchivePopup/DelayArchivePopup';
import styles from './Actions.module.css';
import SendSMSDrawer from 'pages/Onboarding/PLA/Report/SendSMSDrawer/SendSMSDrawer';

import LaunchDealModal from 'components/Dashboard/LaunchDealModal';
import ReadyLaunchModal from 'components/Dashboard/LaunchDealModal/ReadyLaunchModal/ReadyLaunchModal';
import ReadyLaunchDealModal from 'components/Dashboard/LaunchDealModal/ReadyLaunchDealModal';

export default function Actions({
  meta: { status, id, type, schedule, need_PLA_review, cognito_token },
  setDeleteModalId,
  setNotesDrawer,
  updateData,
  isReport,
  data,
}) {
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const [unarchiveModalVisible, setUnarchiveModalVisible] = useState(false);
  const [launchDealModalVisible, setLaunchDealModalVisible] = useState(false);
  const [readyLaunchModalVisible, setReadyLaunchModalVisible] = useState(false);
  const [readyLaunchDealModalVisible, setReadyLaunchDealModalVisible] = useState(false);
  const [isBeginScenarioOpen, setIsBeginScenarioOpen] = useState(false);
  const [withdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [isBeginScenarioFailed, toggleIsBeginScenarioFailed] = useReducer((s) => !s, false);
  const [isApproveDealOpen, toggleIsApproveDealOpen] = useReducer((s) => !s, false);
  const [isArchiveDelayOpen, toggleIsArchiveDelayOpen] = useReducer((s) => !s, false);
  const [hideAlert, setHideAlert] = useState(false);
  const history = useHistory();
  const role = useSelector((s) => s.user.userData?.role);
  const dispatch = useDispatch();
  const { endLarCreation } = useLarApi();
  const params = useParams();
  const API = useMemo(() => new LARAPI(id), [id]);
  const APISettings = useMemo(() => new SettingsAPI(id), [id]);

  const handleArchive = useCallback(
    ({ reason, additionalInfo }) => {
      API.archive(reason, additionalInfo).then(() => {
        setArchiveModalVisible(false);
        updateData();
      });
    },
    [API, updateData],
  );

  const handleWithdraw = useCallback(
    ({ reason, additionalInfo }) => {
      API.archive(reason, additionalInfo).then(() => {
        setWithdrawModalVisible(false);
        updateData();
      });
    },
    [API, updateData],
  );

  const handleUnarchive = useCallback(() => {
    API.unarchive().then(() => {
      message.success('You successfully restored the deal');
      setUnarchiveModalVisible(false);
      updateData();
    });
  }, [API, updateData]);

  const handleVerifyDeal = useCallback(() => {
    APISettings.postLarScheduleStatus(id).then(() => {
      message.success('You successfully verified deal');
      updateData();
    });
  }, [API, updateData]);

  function handleBeginScenario() {
    endLarCreation({
      id: +id,
    })
      .then((res) => {
        setHideAlert(true);
        setIsBeginScenarioOpen(false);
        if (res?.response?.data?.error) {
          toggleIsBeginScenarioFailed();
        } else {
          message.success(`You started Scenario #${id}`);
        }
      })
      .catch(() => setIsBeginScenarioOpen(false));
  }

  const handleApprove = () => {
    toggleIsApproveDealOpen();
    endLarCreation({ id: +id })
      .then(() => {
        message.success(`You approved Deal #${id}`);
        updateData();
      })
      .catch(() => message.error('Error'));
  };

  const handleArchiveDelay = () => {
    toggleIsArchiveDelayOpen();
  };
  const postEndCreation = async (type) => {
    try {
      await LegacyApi().post('/LAR/creation/end_creation', { id, type });
      message.success(
        type === 'auction'
          ? 'Auction is ready to be scheduled'
          : 'Scenario launched successfully. Deal moved to Scenario Pipeline',
      );
      updateData();
    } catch (e) {
      console.log(e);
    }
  };
  const handleEndCreation = async (isScenario) => {
    if (isScenario) {
      handleToggleReadyLaunchDeal();
    } else {
      await postEndCreation('auction');
      history.push(`/onboarding/auction/${id}/schedule`);
    }
  };

  const handleToggleBeginScenario = () => setIsBeginScenarioOpen(!isBeginScenarioOpen);

  const handleToggleLaunchDeal = () => setLaunchDealModalVisible((prev) => !prev);
  const handleToggleReadyLaunch = () => setReadyLaunchModalVisible((prev) => !prev);
  const handleToggleReadyLaunchDeal = () => setReadyLaunchDealModalVisible((prev) => !prev);
  const [activeModal, setActiveModal] = useState({ show: false, data: null });

  const onCloseActiveModal = () => setActiveModal({ show: false, data: null });

  const onOpenActiveModal = (data) => setActiveModal({ data, show: true });

  const isBorrower = useMemo(() => /^superadmin$|borrower/i.test(role), [role]);
  const isBroker = useMemo(() => /^superadmin$|broker/i.test(role), [role]);
  const isLender = useMemo(() => /^superadmin$|lender/i.test(role), [role]);
  const isPLA = useMemo(() => /^superadmin$|pla/i.test(role), [role]);

  const menuItemsReport = useMemo(() => {
    function sendSms() {
      data.user && onOpenActiveModal(data.user);
    }
    function openMandate() {
      history.push({
        pathname: `/onboarding/mandates/edit_mandate/${data.mandate_id}`,
        state: {
          company_id: data.company_id,
        },
      });
    }
    async function invitePeople() {
      try {
        await LegacyApi().post('/LAR/invite', {
          lar_id: Number(params.id),
          mandate_id: data.mandate_id,
        });
        message.success('Invitation has been delivered');
      } catch (e) {
        console.log(e);
      }
    }

    return [
      {
        key: 'send-sms',
        label: <div onClick={sendSms}>Send SMS</div>,
      },
      {
        key: 'view-mandate',
        label: <div onClick={openMandate}>View Mandate</div>,
      },
      ...(/Scheduled|Invites Sent|Live Auction|Active/i.test(status)
        ? [
            {
              key: 'invite',
              label: <div onClick={invitePeople}>Invite</div>,
            },
          ]
        : []),
    ];
  }, [status]);

  const menuItems = useMemo(() => {
    function editApplication() {
      history.push(`/lar_app/create${cognito_token}`);
    }

    function openSettings() {
      history.push(`/onboarding/lar_db/${id}/settings`);
    }

    function openProfile() {
      history.push(`/onboarding/loan_profile/${id}`);
    }

    function handleSchedule() {
      history.push(`/onboarding/auction/${id}/schedule`);
    }

    function archive() {
      setArchiveModalVisible(true);
    }

    function unarchive() {
      setUnarchiveModalVisible(true);
    }

    function openLaunchDeal() {
      handleToggleLaunchDeal();
      // console.log(data);
      // handleToggleReadyLaunchDeal();
    }

    function withdraw() {
      setWithdrawModalVisible(true);
    }

    function viewQuote() {
      history.push(`/onboarding/lar_db/${id}/auction`);
    }

    function messageBroker() {
      dispatch({
        type: 'CHAT_STATE',
        payload: {
          lar_id: +id,
          recipient_role: 'Broker Loan Manager',
          type: 'sidebar',
        },
      });
    }

    return [
      ...((isBroker || isPLA) && /auction|scenario/i.test(type) && /Pre Auction|PLA Review/i.test(status)
        ? [
            {
              key: 'verify',
              label: <div onClick={handleVerifyDeal}>{isPLA ? 'Approve for Launch' : 'Verify'}</div>,
            },
          ]
        : []),
      ...((isBroker || isPLA) && /Launch Approved/i.test(status)
        ? [
            {
              key: 'launch-request',
              label: <div onClick={openLaunchDeal}>Launch Request</div>,
            },
          ]
        : []),
      ...((/incomplete|active|Pending Review|PLA Review|pre auction/i.test(status) &&
        !/scenario/i.test(type) &&
        isBroker) ||
      (/incomplete|Pending Review|PLA Review|Launch Approved/i.test(status) &&
        /scenario|pending/i.test(type) &&
        isBroker)
        ? [
            {
              key: 'edit-application',
              label: <div onClick={editApplication}>Edit application</div>,
            },
          ]
        : []),
      ...(/pre auction|incomplete|PLA Review/i.test(status) && /scenario/i.test(type) && isPLA
        ? [
            {
              key: 'begin-scenario',
              label: <div onClick={handleToggleBeginScenario}>Begin Scenario</div>,
            },
          ]
        : []),
      ...((isBroker || isPLA || isBorrower) &&
      /Active|Quote Selection/i.test(status) &&
      /scenario|auction|scenarios/i.test(type)
        ? [
            {
              key: 'extend-deal',
              label: <div onClick={handleArchiveDelay}>Extend 3 days</div>,
            },
          ]
        : []),
      ...((isBroker || isPLA) && !/incomplete|draft/i.test(status)
        ? [
            {
              key: 'settings',
              label: <div onClick={openSettings}>Settings</div>,
            },
          ]
        : []),
      ...(/pre auction|scheduled/i.test(status) && (isBroker || isPLA) && schedule
        ? [
            {
              key: 'schedule',
              label: <div onClick={handleSchedule}>Schedule</div>,
            },
          ]
        : []),
      ...(/pre auction|scheduled/i.test(status) && (isBroker || isPLA) && !schedule
        ? [
            {
              key: 'schedule-2',
              label: <div onClick={handleSchedule}>Schedule</div>,
            },
          ]
        : []),
      ...(/Pending Review/i.test(status) && isBroker
        ? [
            {
              key: 'approve',
              label: <div onClick={toggleIsApproveDealOpen}>Send for Review</div>,
            },
          ]
        : []),
      ...(!/incomplete|draft/i.test(status)
        ? [
            {
              key: 'profile',
              label: <div onClick={openProfile}>Profile</div>,
            },
          ]
        : []),
      ...(/pre auction|active|PLA Review|/i.test(status) && isBroker
        ? [
            {
              key: 'archive',
              label: <div onClick={archive}>Archive</div>,
            },
          ]
        : []),
      ...(/archive/i.test(status) && isBroker
        ? [
            {
              key: 'restore',
              label: <div onClick={unarchive}>Restore</div>,
            },
          ]
        : []),
      ...(/winner selected/i.test(status) && isLender
        ? [
            {
              key: 'view-quote',
              label: <div onClick={viewQuote}>View quote</div>,
            },
          ]
        : []),
      ...(/loan processing/i.test(status) && isLender
        ? [
            {
              key: 'withdraw',
              label: <div onClick={withdraw}>Withdraw</div>,
            },
          ]
        : []),
      ...(isBorrower
        ? [
            {
              key: 'open-chat',
              label: <div onClick={messageBroker}>Message broker</div>,
            },
          ]
        : []),
      // ...(isPLA
      //   ? [
      //       {
      //         key: 'delay-archive',
      //         label: <div onClick={toggleIsArchiveDelayOpen}>Delay Archive</div>,
      //       },
      //     ]
      //   : []),
    ];
  }, [status, history, id, type, isBroker, isLender, isBorrower, isPLA, dispatch]);

  return (
    <div className={styles.actions} onClick={(e) => e.stopPropagation()}>
      {!schedule && !hideAlert && (
        <>
          {/* {/pre auction/i.test(status) && /pending|auction/i.test(type) && /broker/i.test(role) && (
            <Tooltip
              title={`Please wait for your personal loan advisor to check your deal and ${
                /pre auction/i.test(status) ? 'enable scheduling' : 'begin the scenario'
              }`}
            >
              <img src={alertTriangle} alt="alert" />
            </Tooltip>
          )} */}
          {/PLA Review|pre auction/i.test(status) && /scenario|auction/i.test(type) && isPLA && need_PLA_review && (
            <Tooltip title="This deal is waiting for your review">
              <img src={alertTriangle} alt="alert" />
            </Tooltip>
          )}
        </>
      )}

      {/draft|incomplete|archive/i.test(status) && !/lender/i.test(role) && (
        <BinIcon onClick={() => setDeleteModalId(id)} />
      )}
      {!isReport && <NoteIcon onClick={() => setNotesDrawer(id)} />}
      <Dropdown menu={{ items: isReport ? menuItemsReport : menuItems }}>
        <HorizontalDotsIcon />
      </Dropdown>
      <WithdrawModal
        onCancel={() => setArchiveModalVisible(false)}
        onOk={handleArchive}
        visible={archiveModalVisible}
      />
      <WithdrawModal
        withdraw
        onCancel={() => setWithdrawModalVisible(false)}
        onOk={handleWithdraw}
        visible={withdrawModalVisible}
      />
      <Modal
        okText="Confirm"
        title="Are you sure you want to restore this deal?"
        onOk={handleUnarchive}
        open={unarchiveModalVisible}
        onCancel={() => setUnarchiveModalVisible(false)}
      >
        <Typography.Paragraph>
          Restored deals will be added to the Pending pipeline table where you can review, edit, and select to resubmit
          as a scenario or auction.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Note that all activity and messages will be erased from the deal when it is restored. Only information from
          the loan request form will be retained.
        </Typography.Paragraph>
      </Modal>
      <Modal
        okText="Confirm"
        title="Begin scenario"
        onOk={handleBeginScenario}
        open={isBeginScenarioOpen}
        onCancel={handleToggleBeginScenario}
      >
        <Typography.Paragraph>Confirm this information is correct and begin the scenario process</Typography.Paragraph>
      </Modal>
      <Modal
        okText="Approve"
        title="Submit for Review"
        onOk={handleApprove}
        open={isApproveDealOpen}
        onCancel={toggleIsApproveDealOpen}
      >
        <Typography.Paragraph>
          After Starport's review and approval you will be able to launch a Scenario or Arena Auction.
        </Typography.Paragraph>
      </Modal>
      <DelayArchivePopup
        id={id}
        isOpen={isArchiveDelayOpen}
        onSubmit={handleArchiveDelay}
        onCancel={toggleIsArchiveDelayOpen}
      />
      <LaunchDealModal
        id={id}
        onCancel={handleToggleLaunchDeal}
        isOpen={launchDealModalVisible}
        onOk={handleEndCreation}
      />
      {/Launch Approved/i.test(status) && (
        <ReadyLaunchModal
          onCancel={handleToggleReadyLaunch}
          isOpen={readyLaunchModalVisible}
          onOk={handleEndCreation}
        />
      )}
      {/Launch Approved/i.test(status) && (
        <ReadyLaunchDealModal
          isOpen={readyLaunchDealModalVisible}
          onCancel={handleToggleReadyLaunchDeal}
          onOk={() => postEndCreation('scenario')}
        />
      )}
      <Modal
        okText="Revise"
        title="Begin scenario"
        open={isBeginScenarioFailed}
        onCancel={toggleIsBeginScenarioFailed}
        onOk={() => history.push(`/lar_app/create?lar_id=${id}`)}
      >
        <NoResults />
      </Modal>
      {isReport && <SendSMSDrawer onClose={onCloseActiveModal} activeModal={activeModal} />}
    </div>
  );
}
