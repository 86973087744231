import { Image } from 'antd';
import ReactPlayer from 'react-player';
import ReactYoutubePlayer from 'react-player/youtube';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Navigation } from 'swiper';

import larPlaceholderImg from '../../../../../assets/images/LAR_placeholder.svg';

import styles from './LoanProfileMedia.module.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/modules/navigation/navigation.min.css';

SwiperCore.use([Navigation]);

export default function LoanProfileMedia({ media }) {
  return media.length === 0 ? (
    <Image src={larPlaceholderImg} width="100%" alt="" />
  ) : (
    <div className={styles.carouselWrapper}>
      <Swiper navigation slidesPerView={1}>
        {media.map((el) => (
          <SwiperSlide key={el.id}>
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            {el.type === 'image' && <img alt="Property photo" src={el?.elem || larPlaceholderImg} width="100%" />}
            {el.type === 'youtube' && <ReactYoutubePlayer height={257} width="auto" url={el?.elem} />}
            {el.type === 'video' && <ReactPlayer controls height={257} url={el?.elem} width="100%" />}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
