import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, message } from 'antd';
import { isEmpty } from 'lodash';
import API from 'api';
import jwtDecode from 'jwt-decode';
import { setChatState, setUserData } from 'store/actions/userActions';
import { formatPhone } from 'constants/formatPhone';
import CustomButton from 'components/UI/Button';
import chevronIcon from 'assets/images/chevron-down.svg';
import CustomAvatar from 'components/UI/Avatar';
import DropdownMenu from 'components/CustomComponents/DropdownMenu';
import Keys from 'constants/helper';
import LARCard from 'components/LARDB/LARCard';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import TextArea from 'components/CustomComponents/TextArea';
import useQuery from 'constants/useQuery';
import { faq } from '../../../constants/faq';
import { rejectReasons } from '../../../constants/rejectReasons';
import BarChart from 'components/UI/BarChart';
import QuoteTable from 'components/LARDB/SelectQuotes/QuoteSummary/QuoteTable/QuoteTable';
import '../styles/auction-dashboard.css';
import { getClosingCost, getDownPayment } from 'utils/formula';
import classes from './styles/AuctionDashboard.module.css';

const initialConfirm = { visible: false, data: {} };
const initialReject = {
  visible: false,
  data: {
    reason: {
      value: '',
      error: false,
      errorMessage: 'Fill this label',
    },
    details: {
      value: '',
      error: false,
      errorMessage: 'Fill this label',
    },
  },
};

export default function AuctionDashboard() {
  const [activeFAQ, setActiveFAQ] = useState(null);
  const [broker, setBroker] = useState({});
  const [comment, setComment] = useState('');
  const [confirmQuote, setConfirmQuote] = useState(initialConfirm);
  const [id, jwt, previewMode, quotesSent, scenarioParam] = useQuery([
    'lar_id',
    'jwt',
    'preview',
    'quotes_sent',
    'scenario',
  ]);
  const [LAR, setLAR] = useState({});
  const [quotes, setQuotes] = useState([]);
  const [rejectQuotes, setRejectQuotes] = useState(initialReject);
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef(null);
  const isScenario = scenarioParam === 'true';
  const role = useSelector((state) => state?.user?.userData?.role);
  const { feesWorksheet } = useSelector((state) => state);

  useEffect(() => {
    if (jwt) {
      decodeSaveToken(jwt);
    }
    getQuotes();
    // eslint-disable-next-line
  }, [id, jwt]);

  const decodeSaveToken = (token) => {
    const jwtData = jwtDecode(token);
    localStorage.setItem(Keys.JWT_TOKEN, token);
    dispatch(
      setUserData({
        role: jwtData?.role,
        token,
      }),
    );
  };

  const getQuotesFromStorage = () => {
    const quotesFromStorage = JSON.parse(sessionStorage.getItem(Keys.WINNER_SELECTION_PREVIEW)) || {};
    return quotesFromStorage[id] || [];
  };

  const getQuotes = () => {
    if (previewMode && !quotesSent) {
      const quotes = getQuotesFromStorage();
      setQuotes(quotes);
    }

    if (previewMode) {
      setQuotes(getQuotesFromStorage());
    }

    API(jwt)
      .get(isScenario ? '/LAR/scenario/borrower_accept_quote' : '/LAR/quotes/borrower', { params: { lar_id: id } })
      .then(({ data }) => {
        const { access_token, broker, lar, bids, winner, comment_from_broker } = data;
        if (access_token) {
          decodeSaveToken(access_token);
        }

        if (winner) {
          history.push(`/winner-selection/confirm?lar_id=${id}`, {
            ...winner,
            lar_id: id,
          });
          return;
        }

        const quotes = !isEmpty(bids) ? bids : getQuotesFromStorage();

        setComment(comment_from_broker);
        setBroker(broker);
        setQuotes(quotes);
        setLAR(lar);
      })
      .catch(() => message.error('Something went wrong.'));
  };

  const submitReject = () => {
    let hasError = false;
    const tempReject = { ...rejectQuotes };

    Object.entries(tempReject.data).forEach((i) => {
      if (!i[1].value) {
        hasError = true;
        tempReject.data[i[0]].error = true;
      }
    });

    if (hasError) {
      setRejectQuotes(tempReject);
      return;
    }

    const body = {
      lar_id: +id,
      reason: rejectQuotes.data.reason.value,
      template: rejectQuotes.data.details.value,
    };

    API()
      .post('/LAR/quotes/borrower/reject_quotes', body)
      .then(() => {
        history.push('/onboarding/dashboard');
      });
  };

  const changeRejectQuotes = (name, value) => {
    setRejectQuotes((state) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value,
          error: false,
        },
      },
    }));
  };

  const closeRejectPopup = () => setRejectQuotes(initialReject);

  const submitConfirmQuote = () => {
    API()
      .post('/LAR/quotes/borrower/select_winner', {
        lar_id: +id,
        quote_id: confirmQuote.data.quote_id,
      })
      .then(() => {
        history.push({
          pathname: '/winner-selection/next',
          state: {
            selectedWinnerForLAR: +id,
          },
        });
      });
  };

  const scrollToFAQ = () => {
    if (ref) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const openChat = () => {
    const formattedUser = {
      lar_id: +id,
      recipient_role: broker?.role,
      type: 'sidebar',
    };
    dispatch(setChatState(formattedUser));
  };

  const scheduleCall = () => {
    history.push(broker.scheduler_link);
  };

  const getTheMostLoanAmount = () => {
    let sum = 0;
    quotes?.forEach((quote) => {
      const downPayment = getDownPayment(feesWorksheet, quote) || 0;
      const closingCost = getClosingCost(feesWorksheet, quote) || 0;

      const sumOfDPandCC = downPayment + closingCost;

      if (sumOfDPandCC > sum) {
        sum = sumOfDPandCC;
      }
    });

    return sum;
  };

  const theMostLoanAmount = getTheMostLoanAmount();
  return (
    <div className="auction-dashboard-wrapper">
      <div className="screen-bg" />
      <div className="screen-bg-full" />
      <Modal
        centered
        className="confirm-quote"
        closable={null}
        footer={null}
        onCancel={() => setConfirmQuote(initialConfirm)}
        title={null}
        open={confirmQuote.visible}
        width={496}
      >
        <div className="quote-title">
          <SectionTitle type="heading-20" title="Confirm Your Winning Quote" />
        </div>
        <div className="quote-preview">
          <div className="quote">
            {confirmQuote.data && (
              <LARCard
                data={{
                  ...LAR,
                  ...confirmQuote.data,
                }}
                getQuotes={getQuotes}
                isConfirm
              />
            )}
          </div>
        </div>
        <div className="quote-footer">
          <CustomButton primary text="Confirm" onClick={submitConfirmQuote} style={{ padding: '8px 24px' }} />
        </div>
      </Modal>
      <Modal
        centered
        className="reject-quotes"
        closable={null}
        footer={null}
        onCancel={closeRejectPopup}
        title={null}
        open={rejectQuotes.visible}
        width={512}
      >
        <div className="reject-title">
          <SectionTitle type="regular-24" title="Are you sure to reject all the quotes" />
          <span>
            Please be aware that this action
            <b>is not reversible</b>
          </span>
        </div>
        <div className="reject-body">
          <DropdownMenu
            options={rejectReasons.map((i) => ({
              preview: i,
              value: i,
            }))}
            className="bordered"
            error={rejectQuotes.data.reason.error}
            errorMessage={rejectQuotes.data.reason.errorMessage}
            onSelect={({ value }) => changeRejectQuotes('reason', value)}
            title="Reason"
            value={rejectQuotes.data.reason.value}
            vectorIcon
          />
          <TextArea
            error={rejectQuotes.data.details.error}
            errorMessage={rejectQuotes.data.details.errorMessage}
            label="Additional comment"
            maxlength={400}
            onChange={(e) => changeRejectQuotes('details', e.target.value)}
            rows={5}
            style={{ marginTop: 24 }}
            value={rejectQuotes.data.details.value}
          />
          <div className="attached-broker">
            <CustomAvatar src={broker.profile_photo} fullName={broker.name} size={70} />
            <div className="attached-info">
              <SectionTitle type="heading-20" title={broker.name} />
              <span>{broker.email}</span>
              <span>{formatPhone(broker.phone)}</span>
            </div>
          </div>
          <div className="actions-row">
            <CustomButton primary text="Cancel" onClick={closeRejectPopup} />
            <CustomButton onClick={submitReject} text="Reject All" />
          </div>
        </div>
      </Modal>
      <div className="auction-dashboard">
        <h1 className={classes.title}>
          {'Lender Quotes '}
          {LAR.location?.address},{LAR.location?.city} {LAR.location?.state}
        </h1>
        <p className={classes.subtitle}>Please review these quotes that I've prepared for you</p>
        <section className={classes.contactSection}>
          <div className={classes.contactHeader}>Please contact me if you have any questions</div>
          <div className={classes.contactBody}>
            <div className={classes.contactBodyLeft}>
              {broker.profile_photo && <img className={classes.contactPhoto} src={broker.profile_photo} alt="broker" />}
              <h4 className={classes.contactName}>{broker.name}</h4>
              {broker.phone && (
                <a className={classes.contactPhone} href={`tel:+${broker.phone}`}>
                  {formatPhone(broker.phone)}
                </a>
              )}
              {broker.email && <a href={`mailto:${broker.email}`}>{broker.email}</a>}
            </div>
            <div className={classes.contactBodyRight}>
              <Button onClick={scrollToFAQ}>FAQ</Button>
              {broker.scheduler_link && <Button onClick={scheduleCall}>Schedule a call</Button>}
              {broker.can_send_message && role !== 'Prospect borrower' && (
                <Button onClick={openChat} type="dark">
                  Open Chat
                </Button>
              )}
            </div>
          </div>
          <div className={classes.contactFooter}>
            <span>Message from {broker.name}:</span> {comment}
          </div>
        </section>
        <section className="lar-quotes">
          {!isEmpty(quotes) &&
            quotes.map((i) => (
              <LARCard
                data={{
                  ...LAR,
                  ...i,
                  previewMode,
                }}
                getQuotes={getQuotes}
                key={i.number}
              />
            ))}
        </section>
        <section className={classes.barChartSection}>
          {!isEmpty(quotes) &&
            quotes.map((quote) => (
              <div className={classes.barChartWrapper}>
                <BarChart
                  quote={quote}
                  customQuotes={quotes}
                  theMostLoanAmount={theMostLoanAmount}
                  barTitle="Cash to Close"
                  larId={id}
                />
              </div>
            ))}
        </section>
        <section className={classes.tableSection}>
          {!isEmpty(quotes) && <QuoteTable customQuotes={quotes} larId={id} term={LAR?.loan_term} />}
        </section>
        <div className={classes.box}>
          <h3>Disclaimer</h3>
          <p>
            The quote provided by the lender is not a commitment to make a loan. Loans are subject to borrower
            qualifications, including income, property evaluation, sufficient equity in the property to meet LTV
            requirements, and final credit approval. Lender approvals are subject to underwriting guidelines, interest
            rates, and program guidelines, and are subject to change without notice based on applicant’s eligibility and
            market conditions. Refinancing an existing loan may result in total finance charges being higher over life
            of loan. Reduction in payments may reflect longer loan term. Terms of the loan may be subject to payment of
            points and fees by the applicant.
          </p>
        </div>
        <section className="faq-wrapper" ref={ref}>
          <SectionTitle type="heading-24" title="Frequently asked questions" />
          <div className="faq-list">
            {faq.map((i, idx) => (
              <div className={`faq-item ${idx === activeFAQ ? 'active-faq' : ''}`} key={idx}>
                <div onClick={() => setActiveFAQ((state) => (state === idx ? null : idx))} className="faq-head">
                  <span>{i.title}</span>
                  <img src={chevronIcon} alt="" />
                </div>
                <div className="faq-descriotion">
                  <span>{i.description}</span>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}
