import ReactHtmlParser from 'react-html-parser';
import { useMemo, useReducer, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Tag, Input, Divider, Checkbox } from 'antd';

import API from 'api';
import { CloseIcon } from 'assets/icons/icons';

import styles from './ManualEMSModal.module.css';

function ManualEMSModal({
  mainRecipient,
  onCancel,
  onSecondarySubmit,
  onSubmit,
  optionalRecipients = [],
  templateRoute,
  visible,
  isSending,
  withoutMessage,
  ...rest
}) {
  const [selectedIds, toggleSelectedId] = useReducer((state, id) => {
    if (undefined === id) {
      return new Set();
    }

    const copy = new Set(state);
    state.has(id) ? copy.delete(id) : copy.add(id);
    return copy;
  }, new Set());
  const [message, setMessage] = useState('');
  const [sendCopyToSelf, setSendCopyToSelf] = useState(false);
  const [template, setTemplate] = useState('');
  const currentUser = useSelector((s) => s.user.userData);

  const selectedRecipients = useMemo(
    () =>
      optionalRecipients
        .map((category) => category.items)
        .flat()
        .filter((recipient) => selectedIds.has(recipient.user_id)),
    [optionalRecipients, selectedIds],
  );

  function handleSubmit(secondary) {
    (secondary ? onSecondarySubmit : onSubmit)({
      cc: Array.from(selectedIds),
      message,
      send_copy_to_self: sendCopyToSelf,
      to: mainRecipient,
    });
  }

  // Clean up after closing modal
  useEffect(() => {
    if (visible) {
      return;
    }

    setSendCopyToSelf(false);
    setMessage('');
    toggleSelectedId();
  }, [visible]);

  useEffect(() => {
    if (!visible || !templateRoute) {
      return;
    }

    API()
      .get(templateRoute)
      .then(({ data }) => {
        setTemplate('email_template' in data ? data.email_template : data);
      });
  }, [visible, templateRoute]);

  return (
    <Modal
      {...rest}
      className={styles.root}
      closable={false}
      footer={
        <>
          <Button onClick={onCancel} size="large">
            Cancel
          </Button>
          {onSecondarySubmit && <Button onClick={() => handleSubmit(true)}>Save without sending</Button>}
          <Button onClick={() => handleSubmit()} loading={isSending} size="large" type="dark">
            Save & Send
          </Button>
        </>
      }
      onCancel={onCancel}
      open={visible}
      width="60em"
    >
      <div className={styles.recipientsWrapper}>
        <label>{`${optionalRecipients.length > 0 ? 'Main r' : 'R'}ecipient (To:)`}</label>
        <div className={styles.selectedRecipients}>
          {Array.isArray(mainRecipient) ? (
            mainRecipient.map((r) => <Tag key={r.full_name}>{r.full_name}</Tag>)
          ) : (
            <Tag>{mainRecipient?.full_name || template?.users?.to?.name}</Tag>
          )}
        </div>
        {selectedIds.size > 0 && (
          <>
            <Divider />
            <label>Selected recipients (CC:)</label>
            <div className={styles.selectedRecipients}>
              {selectedRecipients.map(({ user_id, full_name }) => (
                <Tag key={user_id} onClick={() => toggleSelectedId(user_id)}>
                  <CloseIcon />
                  {full_name}
                </Tag>
              ))}
            </div>
          </>
        )}
        {optionalRecipients.length > 0 && (
          <>
            <Divider />
            <label>Available recipients</label>
            <div className={styles.suggestedRecipients}>
              {optionalRecipients.map(({ items, label }) => (
                <div key={label}>
                  <label>{label}:</label>
                  <div>
                    {items.map(({ user_id, full_name }) => (
                      <Button key={user_id} onClick={() => toggleSelectedId(user_id)}>
                        {full_name}
                      </Button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className={styles.templateWrapper}>
        <label>Message</label>
        <div>
          <b>
            From:
            {currentUser.name} {currentUser.surname}
          </b>
          <br />
          <b>
            Subject:
            {template.subject}
          </b>
          <p>{ReactHtmlParser(template.message)}</p>
          <p>{'<Link to this item appears here>'}</p>
        </div>
      </div>
      {!withoutMessage && (
        <div className={styles.messageWrapper}>
          <label>Add a personalised message (Optional)</label>
          <Input.TextArea
            onChange={({ target: { value: v } }) => setMessage(v)}
            placeholder="Message"
            rows={4}
            value={message}
          />
          <Checkbox checked={sendCopyToSelf} onChange={({ target: { checked: c } }) => setSendCopyToSelf(c)}>
            Send a copy to myself
          </Checkbox>
        </div>
      )}
    </Modal>
  );
}

export default ManualEMSModal;
