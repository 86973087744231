import React, { useEffect, useState } from 'react';
import '../../BDR/styles/prospects.css';
import { Breadcrumb, Table, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import API from '../../../../api';
import PaginationItemRender from '../../../../constants/PaginationItemRender';
import UserPreview from '../../../../components/OnBoarding/Users/UserPreview';
import copyIcon from '../../../../assets/images/copy.svg';
import { numberToCurrency } from '../../../../constants/numberToComma';
import UserDrawer from '../../../../components/UserDrawer';

const columns = [
  {
    title: 'LAR ID',
    dataIndex: 'id',
    key: 'id',
    width: 125,
    render: (value) => (
      <span className="id-column">
        #{value}
        <img
          src={copyIcon}
          onClick={() => {
            message.success('Text was copied to clipboard.');
            navigator.clipboard.writeText(value);
          }}
          alt=""
        />
      </span>
    ),
  },
  {
    title: 'LAR Status',
    dataIndex: 'status',
    key: 'status',
    width: 150,
  },
  {
    title: 'LAE Name',
    dataIndex: 'member_name',
    key: 'member_name',
    width: 150,
  },
  {
    title: 'PLA Support',
    dataIndex: 'PLA_support',
    key: 'PLA_support',
    width: 200,
  },
  {
    title: 'LAE Recent Login',
    dataIndex: 'lar_last_login',
    key: 'lar_last_login',
    width: 200,
  },
  {
    title: 'Loan Amount',
    dataIndex: 'loan_amount',
    key: 'loan_amount',
    width: 150,
    render: (value) => numberToCurrency(value),
  },
  {
    title: 'Loan Purpose',
    dataIndex: 'loan_purpose',
    key: 'loan_purpose',
    width: 150,
  },
  {
    title: 'Loan Style',
    dataIndex: 'loan_style',
    key: 'loan_style',
    width: 150,
  },
];

function CompanyUser() {
  const [data, setData] = useState({ loading: false });
  const [userData, setUserData] = useState({ loading: false });
  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const history = useHistory();
  const companyName = history.location.state?.companyName;
  const { userId } = useParams();

  const goToCompanies = () => {
    history.push({
      pathname: '/onboarding/companies/',
      state: { ...history.location.state },
    });
  };

  const onCloseActiveModal = () => setActiveModal((state) => ({ ...state, show: false }));

  const onOpenActiveModal = () => setActiveModal((state) => ({ ...state, show: true }));

  const getUserData = () => {
    API()
      .get(`/superadmin/user?id=${userId}`)
      .then((res) => {
        setUserData({ loading: true, ...res.data });
      });
  };

  const getUserCompanyData = () => {
    API()
      .get('/superadmin/company/user', {
        params: { user_id: userId },
      })
      .then((res) => {
        setPagination((prevState) => ({ ...prevState, total: res.data.total }));
        setData({ loading: true, ...res.data });
      });
  };

  useEffect(() => {
    getUserCompanyData();
    // eslint-disable-next-line
  }, [pagination.current, pagination.pageSize]);

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="prospects_page_wrapper table_with_header_wrapper table_with_pagination_wrapper">
        <Breadcrumb style={{ marginBottom: 15 }}>
          <Breadcrumb.Item
            href=""
            onClick={(e) => {
              e.preventDefault();
              goToCompanies();
            }}
          >
            Companies
          </Breadcrumb.Item>
          <Breadcrumb.Item
            href=""
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            {companyName}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="section_title_wrapper">
          <UserPreview name={data?.name} avatar={data?.profile_photo} onView={onOpenActiveModal} />
        </div>
        <div className="section_table_wrapper table_with_header table_with_pagination">
          <Table
            columns={columns}
            pagination={{
              showSizeChanger: true,
              defaultCurrent: pagination.current,
              total: pagination.total ?? null,
              itemRender: PaginationItemRender,
            }}
            onChange={(e) => setPagination(e)}
            scroll={{ x: 1100 }}
            rowKey="id"
            title={null}
            border
            bordered={false}
            dataSource={data.lars}
          />
        </div>
      </div>
      <UserDrawer user={userData} visible={activeModal.show} onClose={onCloseActiveModal} />
    </>
  );
}

export default CompanyUser;
