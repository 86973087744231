import '../../BDR/styles/prospects.css';
import '../Users/styles/styles.css';
import { capitalizeFirstLetter } from 'constants/capitalizeFirstLetter';
import { message, Table } from 'antd';
import { setBreadCrumbHistorySetValue } from 'store/actions/userActions';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import API from 'api';
import BreadCrumbHistory from 'components/BreadCrumbHistory';
import copyIcon from 'assets/images/copy.svg';
import PaginationItemRender from 'constants/PaginationItemRender';
import SectionTitle from 'components/OnBoarding/SectionTitle';
import UserDrawer from 'components/UserDrawer';
import UserPreview from 'components/OnBoarding/Users/UserPreview';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 125,
    render: (value) => (
      <span className="id-column">
        {value}
        <img
          src={copyIcon}
          onClick={() => {
            message.success('Text was copied to clipboard.');
            navigator.clipboard.writeText(value);
          }}
          alt=""
        />
      </span>
    ),
  },
  {
    title: 'LAR Name',
    dataIndex: 'LAR_name',
    key: 'LAR_name',
    width: 150,
  },
  {
    title: 'PLA',
    dataIndex: 'name',
    key: 'name',
    width: 200,
  },
  {
    title: 'PLA Support',
    dataIndex: 'PLA_support',
    key: 'PLA_support',
    width: 200,
  },
  {
    title: 'LOM',
    dataIndex: 'LOM',
    key: 'LOM',
    width: 200,
  },
  {
    title: 'LOM Support',
    dataIndex: 'LOM_support',
    key: 'LOM_support',
    width: 150,
    render: (value) => value || <span style={{ color: '#8C8C8C' }}>Not assigned</span>,
  },
];

export default function User() {
  const [activeModal, setActiveModal] = useState({ show: false, data: null });
  const [breadcrumbHistory, setBreadcrumbHistory] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [prospects, setProspects] = useState({ ready: false, total: 0 });
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const role = useSelector((store) => store.user.userData?.role);

  useEffect(() => {
    API()
      .get('/superadmin/user', {
        params: {
          id: +id,
        },
      })
      .then(({ data }) => {
        setActiveModal((prev) => ({ ...prev, data }));
      });
  }, [id]);

  useEffect(() => {
    API()
      .get('/superadmin/user/LARs', {
        params: {
          id: +id,
          page: pagination.current,
          per_page: pagination.pageSize,
        },
      })
      .then(({ data }) => {
        setProspects({ ready: true, ...data });

        const pathArray = location.pathname.split('/');
        setBreadcrumbHistory([
          {
            preview: capitalizeFirstLetter(pathArray[2]),
            to: {
              pathname: pathArray.slice(0, 3).join('/'),
              state: { tab: location.state?.tab },
            },
          },
          {
            preview: data.user.full_name,
            to: location.pathname,
          },
        ]);
      });
  }, [location, id, pagination, role]);

  return (
    <div className="table_with_header_wrapper table_with_pagination_wrapper">
      {breadcrumbHistory.length > 0 && (
        <BreadCrumbHistory
          goBack={(key) => dispatch(setBreadCrumbHistorySetValue(key))}
          history={breadcrumbHistory}
          historyWeb={history}
        />
      )}
      <div className="section_title_wrapper">
        <UserPreview
          avatar={prospects?.user?.profile_photo}
          enabled={activeModal?.data?.enabled}
          name={prospects?.user?.full_name}
          onView={() => setActiveModal((prev) => ({ ...prev, show: true }))}
        />
      </div>
      <div className="section_table_wrapper table_with_header table_with_pagination">
        <Table
          border
          bordered={false}
          columns={columns}
          dataSource={prospects.LARs}
          pagination={{
            ...pagination,
            itemRender: PaginationItemRender,
            showSizeChanger: true,
            total: prospects.total,
          }}
          loading={!prospects.ready}
          onChange={(p) => setPagination(p)}
          scroll={{ x: 1100 }}
          rowKey="id"
          onRow={(record) => ({
            onClick: () => {
              history.push({
                pathname: `/onboarding/lar_db/${record.id}`,
                state: { pageBg: '#F5F5F5' },
              });
            },
          })}
          title={() => (
            <div className="table-header-wrapper">
              <SectionTitle title="LARs" type="subheading-16" />
            </div>
          )}
        />
      </div>
      <UserDrawer
        onClose={() => setActiveModal((prev) => ({ ...prev, show: false }))}
        user={activeModal.data?.user_json}
        visible={activeModal.show}
      />
    </div>
  );
}
