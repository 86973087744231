import { useRef } from 'react';
import styles from './Company.module.css';
import { Badge, Form, Input } from 'antd';
import CustomAvatar from 'components/UI/Avatar/Avatar';
import { acceptedMimes } from 'constants/mimes';
import { PencilIcon } from 'assets/icons';

function CompanyEdit({ form }) {
  const uploadRef = useRef(null);
  return (
    <div>
      <Form
        className={styles.form}
        form={form}
        initialValues={{}}
        layout="vertical"
        onFinish={(values) => {
          console.log(values);

          // const newData = {
          //   name: values.full_name || user.name,
          //   id: user.id,
          //   email: values.email || user.email,
          //   role_name: user.role,
          //   surname: user.surname || '',
          // };
          // handleSave(newData);
        }}
        requiredMark={false}
      >
        <div className={styles.section}>
          <div className={styles.avatarWrapper}>
            <input
              hidden
              type="file"
              ref={uploadRef}
              accept={acceptedMimes.avatar}
              // onChange={handleSelectPic}
              // onClick={resetFileInput}
            />
            <Badge count={<PencilIcon colour="white" />} onClick={() => uploadRef.current?.click()}>
              <CustomAvatar
                // name={profileData.name}
                // src={pic.thumb || profileData.avatar || profileData.profile_photo}
                // surname={profileData.surname}
                size={72}
              />
            </Badge>
          </div>
          <div>
            <p className={styles.usernameTitle}>Leon Cooper</p>
            <p className={styles.subTitle} style={{ marginBottom: 8 }}>
              Created: 12 May 2024
            </p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Form.Item
              label="Full Name"
              name="full_name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
        <div className={styles.section}>
          <Form.Item
            label="Full Name"
            name="full_name"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default CompanyEdit;
