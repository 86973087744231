import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChatState } from 'store/actions/userActions';
import { MailDoubleIcon } from 'assets/icons/icons';
import { replacedTextData } from 'constants/common';
import UserDrawer from 'components/UserDrawer';
import CustomAvatar from 'components/UI/Avatar';
import moment from 'moment';

function TeamMember({ canSendMessage, position, larId, user }) {
  const [userDrawer, setUserDrawer] = useState({ visible: false, data: null });
  const { id: myId } = useSelector((store) => store.user.userData || {});
  const dispatch = useDispatch();

  function handleOpenUserDrawer() {
    setUserDrawer({ visible: true, data: user });
  }

  function handleOpenChat() {
    dispatch(
      setChatState({
        larId,
        recipient_role: position,
        type: 'sidebar',
      }),
    );
  }

  function getLastOnline(date) {
    if (date && date !== 'Invalid date') {
      return moment(date).format('D.MM hh:mm');
    }
    return 'unknown';
  }

  if (!user?.full_name) {
    return null;
  }

  return (
    <div className="team-members__member">
      <div className="team-members__member-position">
        {replacedTextData[position] || position || 'unknown position'}
      </div>
      <div className="team-members__member-data">
        <div className="team-members__member-info" onClick={handleOpenUserDrawer}>
          {user.avatar && (
            <div className="team-members__member-avatar-container">
              <CustomAvatar src={user.avatar} size={40} />
            </div>
          )}
          <div className="team-members__status-container">
            <span className="team-members__member-name">{user.full_name || 'unknown name'}</span>
            <span className="team-members__member-online-status">{getLastOnline(user.last_online)}</span>
          </div>
        </div>
        {user.id === myId ? (
          <span className="team-members__member-status">You</span>
        ) : (
          <div className="team-members__actions">
            {canSendMessage && (
              <button
                aria-label="actionOpenChat"
                className="team-members__action-btn team-members__action-btn_purple team-members__action-btn_blue"
                onClick={handleOpenChat}
              >
                <MailDoubleIcon style={{ color: '#fff' }} />
              </button>
            )}
          </div>
        )}
      </div>
      <UserDrawer
        onClose={() => setUserDrawer((prev) => ({ ...prev, visible: false }))}
        user={userDrawer.data}
        visible={userDrawer.visible}
      />
    </div>
  );
}

export default TeamMember;
