import { useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import ActiveLoans from 'assets/icons/ActiveLoans';
import Auctions from 'assets/icons/Auctions';
import Scenarios from 'assets/icons/Scenarios';
import Widget from './Widget';
import styles from './DashboardWidgets.module.css';

function DashboardWidgets({ activeTab, setActiveTab, metrics }) {
  const [full, toggleFull] = useReducer((s) => !s, false);
  const role = useSelector((store) => store.user?.userData?.role);

  const widgetsMeta = useMemo(
    () => [
      {
        dataIndex: 'scenarios',
        extendedDataIndex: 'active_scenarios',
        tabName: 'scenarios',
        title: 'Scenarios',
        icon: <Scenarios colour="grey-7" strokeWidth=".1" />,
      },
      {
        dataIndex: 'auctions',
        extendedDataIndex: 'live_auctions',
        icon: <Auctions colour="grey-7" strokeWidth=".1" />,
        link: /borrower/i.test(role) ? null : '/onboarding/live_auctions',
        tabName: 'auctions',
        title: 'Auctions',
      },
      {
        dataIndex: 'loans',
        extendedDataIndex: 'active_loans',
        tabName: 'active_dials',
        title: 'Active loans',
        icon: <ActiveLoans colour="grey-7" strokeWidth=".1" />,
      },
    ],
    [role],
  );

  return (
    <div className={styles.root}>
      {widgetsMeta.map((w) => (
        <Widget
          {...w}
          activeTab={activeTab}
          full={full}
          key={w.dataIndex}
          metrics={metrics}
          setActiveTab={setActiveTab}
          toggleFull={toggleFull}
        />
      ))}
    </div>
  );
}

export default DashboardWidgets;
